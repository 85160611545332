import { create } from 'zustand';
import { persist } from 'zustand/middleware';

export type ResourceSelectIds = {
  isSelecting: boolean;
  setIsSelecting: (isSelecting: boolean) => void;
  setIds: (ids: string[]) => void;
  ids: string[];
};

const createUseResourceSelectIds = (LS_KEY: string) =>
  create<ResourceSelectIds>()(
    persist(
      (set) => ({
        isSelecting: false,
        setIsSelecting: (isSelecting: boolean) =>
          set((state) => ({
            ids: isSelecting ? state.ids : [],
            isSelecting,
          })),
        ids: [],
        setIds: (ids: string[]) => set(() => ({ ids })),
      }),
      {
        name: LS_KEY,
      },
    ),
  );

const storeCache: { [key: string]: ReturnType<typeof createUseResourceSelectIds>, } = {};

export const useResourceSelectIds = (lsKey: string) => {
  if (!storeCache[lsKey]) {
    storeCache[lsKey] = createUseResourceSelectIds(lsKey);
  }

  return storeCache[lsKey]();
};
