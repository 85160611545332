import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';

import { StyledLink } from '@/components/shared/StyledLink';
import { SelectInput } from '@/components/shared/form/inputs/SelectInput';
import { FieldRenderer } from '@/components/shared/form/FieldRenderer';
import { DebouncedTextInput } from '@/components/shared/form/inputs/DebouncedTextInput';
import { parens } from '@/utils/string';
import { fillValues, hasAnyNonNullish } from '@/utils/object';
import { useBotsFilterParams } from '@/hooks/bot/useBotsFilterParams';
import { getBotStatusLabel } from '@/utils/bot';
import { useBuildClearValue } from '@/hooks/shared';
import { getCompanyOptions, getSubdomainOptions } from '@/utils/filters';
import { getBrandOptions } from '@/utils/filters/brand';
import type { Channel } from '@/types/channel';
import type { BotsFilterParams } from '@/types/bot';
import type { AggregationEntry } from '@/types/shared';

import style from './BotsFilters.module.sass';
import {
  aggregationToCounters,
  getScheduledFilterAvailableValues,
  getSlowFilterAvailableValues,
  getStatusFilterAvailableValues,
} from './utils';

type HiddenFields = {
  companyId?: boolean;
};

type BotsFiltersAggregationMapping = {
  company?: AggregationEntry[];
  duration?: AggregationEntry[];
  status?: AggregationEntry[];
  subdomain?: AggregationEntry[];
  scheduling?: AggregationEntry[];
  brand?: AggregationEntry[];
};

export type BotsFiltersProps = {
  hiddenFields?: HiddenFields;
  channels: Channel[];
  aggregationMapping?: BotsFiltersAggregationMapping;
  disabled?: boolean;
};

const renderCounter = <T extends object>(counters: T, key: keyof T) => {
  if (!counters) {
    return;
  }

  return parens(counters[key] || 0);
};

export const BotsFilters = ({
  hiddenFields,
  channels,
  aggregationMapping,
  disabled,
}: BotsFiltersProps) => {
  const { t } = useTranslation();
  const buildClearValue = useBuildClearValue<BotsFilterParams>();

  const {
    values,
  } = useBotsFilterParams();

  const {
    setValues,
  } = useFormikContext();

  const resetFilterValues = useCallback(() => {
    const emptyValues = fillValues(values, undefined);

    setValues(emptyValues);
  }, [
    values,
    setValues,
  ]);

  const counters = useMemo(() => {
    return aggregationToCounters(aggregationMapping);
  }, [
    aggregationMapping,
  ]);

  const slowOptions = useMemo(() => {
    const availableValues = getSlowFilterAvailableValues(counters);

    return availableValues.map((value) => ({
      value: value,
      label: (
        <SelectInput.OptionWithSubtitle
          label={t(`bot:duration.label.${value}`)}
          subtitle={renderCounter(counters, value)}
        />
      ),
    }));
  }, [
    t,
    counters,
  ]);

  const statusOptions = useMemo(() => {
    const availableValues = getStatusFilterAvailableValues(counters);

    return availableValues.map((value) => ({
      value: value,
      label: (
        <SelectInput.OptionWithSubtitle
          label={getBotStatusLabel(value, t)}
          subtitle={renderCounter(counters, value)}
        />
      ),
    }));
  }, [
    t,
    counters,
  ]);

  const companyOptions = useMemo(() => {
    if (!aggregationMapping?.company) return;

    return getCompanyOptions({
      companyAgg: aggregationMapping.company,
    });
  }, [
    aggregationMapping?.company,
  ]);

  const subdomainAgg = aggregationMapping?.subdomain;

  const channelOptions = useMemo(() => {
    return getSubdomainOptions({
      channels,
      subdomainAgg,
    });
  }, [
    channels,
    subdomainAgg,
  ]);

  const scheduledOptions = useMemo(() => {
    const availableValues = getScheduledFilterAvailableValues(counters);

    return availableValues.map((value) => ({
      value: value,
      label: (
        <SelectInput.OptionWithSubtitle
          label={t(`bot:filterable.options.scheduling.${value}`)}
          subtitle={renderCounter(counters, value)}
        />
      ),
    }));

  }, [
    t,
    counters,
  ]);

  const brandAgg = aggregationMapping?.brand;

  const brandOptions = useMemo(() => {
    return getBrandOptions({
      t,
      brandAgg,
    });
  }, [
    brandAgg,
    t,
  ]);

  const isClearButtonShown = hasAnyNonNullish(values, { ignore: [false, ''] });

  return (
    <div className={style.filtersWrapper}>
      <div className={style.nameFilterWrapper}>
        <FieldRenderer
          name='name'
          Component={DebouncedTextInput}
          clearable={true}
          debounceMs={500}
          sizeVariant='small'
          iconName='Search'
          onClear={buildClearValue('name')}
          placeholder={t('bot:filterable.placeholders.name')}
          disabled={disabled}
        />
      </div>
      <FieldRenderer
        name='subdomainId'
        Component={SelectInput}
        popperAlign='left'
        className={style.filterSelect}
        placeholder={t('bot:filterable.placeholders.subdomainId')}
        optionVariant='radio'
        options={channelOptions}
        popperTitle={t('bot:filterable.titles.subdomainId')}
        deselectable={true}
        disabled={disabled}
        optionClassName={style.option}
        optionsListClassName={style.channelOptionsList}
      />
      {
        hiddenFields?.companyId !== true &&
        <FieldRenderer
          name='companyId'
          Component={SelectInput}
          popperAlign='left'
          className={style.filterSelect}
          optionsClassName={style.companyFilterOptions}
          placeholder={t('bot:filterable.placeholders.companyId')}
          optionVariant='radio'
          options={companyOptions}
          popperTitle={t('bot:filterable.titles.companyId')}
          deselectable={true}
          disabled={disabled}
        />
      }
      <FieldRenderer
        name='brandId'
        Component={SelectInput}
        popperAlign='left'
        className={style.filterSelect}
        placeholder={t('bot:filterable.placeholders.brand')}
        optionClassName={style.option}
        optionVariant='radio'
        options={brandOptions}
        popperTitle={t('bot:filterable.titles.brand')}
        deselectable={true}
        disabled={disabled}
      />
      <FieldRenderer
        name='status'
        Component={SelectInput}
        optionVariant='radio'
        className={style.filterSelect}
        placeholder={t('bot:filterable.placeholders.status')}
        popperAlign='left'
        options={statusOptions}
        popperTitle={t('bot:filterable.titles.status')}
        deselectable={true}
        disabled={disabled}
      />
      <FieldRenderer
        name='duration'
        Component={SelectInput}
        popperAlign='left'
        className={style.filterSelect}
        placeholder={t('bot:filterable.placeholders.duration')}
        optionClassName={style.option}
        optionVariant='radio'
        options={slowOptions}
        popperTitle={t('bot:filterable.titles.duration')}
        deselectable={true}
        disabled={disabled}
      />
      <FieldRenderer
        name='scheduling'
        Component={SelectInput}
        popperAlign='left'
        className={style.filterSelect}
        placeholder={t('bot:filterable.placeholders.scheduling')}
        optionClassName={style.option}
        optionVariant='radio'
        options={scheduledOptions}
        popperTitle={t('bot:filterable.titles.scheduling')}
        deselectable={true}
        disabled={disabled}
      />
      {
        isClearButtonShown &&
        <div className={style.clearWrapper}>
          <StyledLink onClick={resetFilterValues}>
            {t('common:filter.clearFilter')}
          </StyledLink>
        </div>
      }
    </div>
  );
};
