import { memo, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames/bind';
import useUnmount from 'react-use/esm/useUnmount';

import { Container } from '@/components/shared/containers/Container';
import { PageSectionHeader } from '@/components/shared/PageSectionHeader';
import { Bots } from '@/components/bot/Bots';
import { SkeletonLoader } from '@/components/shared/loaders/SkeletonLoader';
import { Pagination } from '@/components/pagination/Pagination';
import { BusyContentContainer } from '@/components/shared/containers/BusyContentContainer';
import { BotsViewToolbar } from '@/components/bot/BotsView/BotsViewToolbar';
import { CreateBotDropdown } from '@/components/bot/CreateBotDropdown';
import { UpdateBotsControls } from '@/components/bot/UpdateBotsControls';
import { useBots, UseBotsParams } from '@/hooks/bot';
import { botStatusToId } from '@/utils/bot';
import { parens } from '@/utils/string';
import { hasAnyNonNullish } from '@/utils/object';
import { useBotsFilterParams } from '@/hooks/bot/useBotsFilterParams';
import { useBotsSortParams } from '@/hooks/bot/useBotsSortParams';
import { usePaginationParams } from '@/hooks/shared/usePaginationParams';
import { useViewModeContext } from '@/contexts/shared';
import { getAvailableAggregationChannels } from '@/utils/filters/getAvailableAggregationChannels';
import { useChannels } from '@/hooks/channel';
import { useBotsResultNumber } from '@/hooks/zustsand/botsResultNumber';
import { useResourceSelectIds } from '@/hooks/zustsand/resourceSelectIds';
import { useIsOnInstance } from '@/hooks/shared';
import { BOTS_SELECTION_KEY } from '@/resources/bot';

import { filterValueToParam } from './utils';
import { useFilteredBotsQueriesMapping } from './hooks';
import style from './BotsView.module.sass';

const cx = classNames.bind(style);

const BOTS_COLUMNS_LENGTH = 4;

type BotsViewProps = {
  companyId?: string;
  canManage: boolean;
  isMainHarvestersView?: boolean;
};

export const BotsView = memo(({
  companyId,
  canManage,
  isMainHarvestersView,
}: BotsViewProps) => {
  const { t } = useTranslation();
  const updateBotsResultNumber = useBotsResultNumber((state) => state.updateBotsResultNumber);

  const {
    values,
  } = useBotsFilterParams();

  const {
    page,
    perPage,
  } = usePaginationParams();

  const {
    sortBy,
  } = useBotsSortParams();

  const slowParam = filterValueToParam(values.duration);
  const scheduledParam = filterValueToParam(values.scheduling);
  const companyIdParam = values.companyId;

  const paginationParams = {
    page,
    perPage,
  };

  const sortParams = {
    sort: sortBy,
  };

  const paramsConfig: UseBotsParams = {
    filter: {
      latestRunSlow: slowParam,
      latestRunStatus: botStatusToId(values.status),
      companyId: (companyId || values.companyId),
      subdomainId: values.subdomainId,
      isScheduled: scheduledParam,
      brandId: values.brandId,
      name: values.name,
    },
    pagination: paginationParams,
    sort: sortParams,
  };

  const {
    dataMapping,
    isLoading: isMetaMappingLoading,
  } = useFilteredBotsQueriesMapping(companyId, paramsConfig);

  const {
    bots,
    meta: scopedMeta,
    isFetching: isBotsLoading,
    error: botsError,
  } = useBots(paramsConfig);

  const {
    channels: allChannels,
    isLoading: isChannelsLoading,
    error: channelsError,
  } = useChannels();

  const botsSubdomains = dataMapping.subdomain?.meta?.aggregation.subdomain;

  const channels = useMemo(() => {
    return getAvailableAggregationChannels(allChannels, botsSubdomains);
  }, [allChannels, botsSubdomains]);

  const {
    isChanging,
    viewMode,
  } = useViewModeContext();

  const isFilterActive = hasAnyNonNullish(values);

  const isLoading = isBotsLoading || isChannelsLoading || isMetaMappingLoading;
  const error = botsError || channelsError;

  const totalEntriesUnfiltered = scopedMeta?.pagination.totalEntriesUnfiltered;

  useEffect(() => {
    updateBotsResultNumber(scopedMeta?.pagination.totalEntries);
  }, [
    scopedMeta?.pagination.totalEntries,
    updateBotsResultNumber,
  ]);

  const { isSelecting,
    setIsSelecting } = useResourceSelectIds(BOTS_SELECTION_KEY);

  useUnmount(() => setIsSelecting(false));
  const { isAdminInstance } = useIsOnInstance();

  const renderHeader = () => {
    const componentClassName = cx({
      sectionWrapper: !isMainHarvestersView,
    });

    const renderToolbar = () => {
      return (
        <BotsViewToolbar
          aggregationMapping={{
            company: dataMapping.company?.meta?.aggregation.company,
            duration: dataMapping.duration?.meta?.aggregation.latestRunSlow,
            status: dataMapping.status?.meta?.aggregation.latestRunStatus,
            subdomain: dataMapping.subdomain?.meta?.aggregation.subdomain,
            scheduling: dataMapping.scheduling?.meta?.aggregation.isScheduled,
            brand: dataMapping.brand?.meta?.aggregation.brand,
          }}
          filterHiddenFields={{
            companyId: Boolean(companyId),
          }}
          areFiltersDisabled={isMetaMappingLoading}
          channels={channels}
          filterValues={values}
          totalEntries={scopedMeta?.pagination.totalEntries}
        />
      );
    };

    if (isMainHarvestersView) {
      const isCompanyFilterSelected = Boolean(companyIdParam);
      const shouldShowMultipleSelectionButton = isAdminInstance && isCompanyFilterSelected;

      return (
        <>
          <PageSectionHeader
            title={t('bot:bots')}
            className={componentClassName}
            wrapperClassName={style.mainPageWrapper}
            subtitle={totalEntriesUnfiltered ? parens(totalEntriesUnfiltered) : ''}>
            <PageSectionHeader.AsideActions>
              <div className={style.controls}>
                {!isSelecting && <CreateBotDropdown />}
                {
                  (canManage && companyIdParam) &&
                  shouldShowMultipleSelectionButton &&
                  <UpdateBotsControls
                    companyId={companyIdParam}
                  />
                }
              </div>
            </PageSectionHeader.AsideActions>
          </PageSectionHeader>
          {renderToolbar()}
        </>
      );
    }

    return (
      <div className={style.toolbar}>
        {renderToolbar()}
      </div>
    );
  };

  return (
    <Container>
      {renderHeader()}
      <SkeletonLoader
        listColumnsLength={BOTS_COLUMNS_LENGTH}
        isLoading={isLoading}
        error={error}
        viewMode={viewMode}
        filterValues={values}>
        {
          bots &&
          <BusyContentContainer isBusy={isChanging}>
            <Bots
              bots={bots}
              pages={scopedMeta?.pagination.totalPages || 0}
              isFilterActive={isFilterActive}
            />
          </BusyContentContainer>
        }
        {
          scopedMeta &&
          <Pagination
            itemsCount={bots?.length}
            totalItemsCount={scopedMeta.pagination.totalEntries}
            pages={scopedMeta.pagination.totalPages}
          />
        }
      </SkeletonLoader>
    </Container>
  );
});

BotsView.displayName = 'BotsView';
