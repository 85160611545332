import { useTranslation } from 'react-i18next';
import { FormEvent, useCallback, useRef, useState } from 'react';
import classNames from 'classnames/bind';

import { Icon } from '@/components/shared/Icon';
import { Dropdown } from '@/components/shared/Dropdown';
import { Button } from '@/components/shared/buttons';
import { Header } from '@/components/shared/typography/Header';
import { Tooltip } from '@/components/shared/Tooltip';
import { ViewMode } from '@/resources/enums';
import { useSelectedColumns as useSelectedColumnsHook } from '@/hooks/zustand/useSelectedColumns';

import style from './TableSettings.module.sass';

const cx = classNames.bind(style);

export type TableOption = {
  hidden?: boolean;
  id: string;
  label: string;
  disabled?: boolean;
}

export type UseSelectedColumnsHook = ReturnType<typeof useSelectedColumnsHook>;

type TableSettingsProps = {
  options: TableOption[];
  useSelectedColumns: UseSelectedColumnsHook;
  title?: string;
  deselectedCount?: number;
  viewMode?: ViewMode;
}

export const TableSettings = ({
  options,
  useSelectedColumns,
  title,
  viewMode = ViewMode.List,
}: TableSettingsProps) => {
  const { t } = useTranslation();
  const {
    selectedColumns,
    setSelectedColumns,
    resetColumns,
    getDeselectedCount,
  } = useSelectedColumns;
  const [selected, setSelected] = useState<string[]>(selectedColumns);
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const timeoutId = useRef<NodeJS.Timeout | null>(null);

  const deselectedCount = getDeselectedCount();

  const handleCheckboxChange = (checked: boolean, id: string) => {
    if (options.find((option) => option.id === id)?.disabled) {
      return;
    }

    setSelected((prev) =>
      checked ? [...prev, id] : prev.filter((item) => item !== id),
    );
  };

  const renderIcon = (checked: boolean, disabled?: boolean) => {
    if (!checked) {
      return null;
    }

    const iconClassNames = cx('icon', {
      disabled,
    });

    return (
      <div className={style.iconWrapper}>
        <Icon name='Done' className={iconClassNames} />
      </div>
    );
  };

  const onSubmit = (event: FormEvent) => {
    if (timeoutId.current) {
      clearTimeout(timeoutId.current);
    }

    event.preventDefault();
    setSelectedColumns(selected);
    setIsTooltipOpen(true);

    timeoutId.current = setTimeout(() => {
      setIsTooltipOpen(false);
    }, 1000);
  };

  const resetForm = () => {
    setSelected(selectedColumns);
  };

  const resetColumnsAndCloseMenu = (closeMenu: () => void) => {
    if (timeoutId.current) {
      clearTimeout(timeoutId.current);
    }

    resetColumns();
    closeMenu();
    setIsTooltipOpen(true);

    timeoutId.current = setTimeout(() => {
      setIsTooltipOpen(false);
    }, 1000);
  };

  const renderOptions = useCallback(() => {
    return options.map((option) => {
      if (option.hidden) {
        return null;
      }

      const labelWrapperClassNames = cx({
        disabled: option.disabled,
      });

      const labelClassnames = cx('label', {
        disabled: option.disabled,
      });

      const checkboxVisualClassNames = cx('checkboxVisual', {
        disabled: option.disabled,
      });

      return (
        <div key={option.id} className={labelWrapperClassNames}>
          <label className={labelClassnames}>
            <input
              className={style.hiddenInput}
              type='checkbox'
              name={option.id}
              checked={option.disabled || selected.includes(option.id)}
              onChange={(e) => handleCheckboxChange(e.target.checked, option.id)}
              value={option.id}
            />
            <div className={checkboxVisualClassNames}>
              {renderIcon(selected.includes(option.id), option.disabled)}
            </div>
            {option.label}
          </label>
        </div>
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  const displayOptionsTransKey = viewMode === ViewMode.List
    ? 'tableSettings:listViewHiddenColumns'
    : 'tableSettings:gridViewHiddenItems';

  const tooltipContent = deselectedCount
    ? t(displayOptionsTransKey, { count: deselectedCount })
    : t('tableSettings:displayOptions');

  const hiddenColumnsTooltipContent = deselectedCount
    ? viewMode === ViewMode.List
      ? t('tableSettings:hiddenColumns', { count: deselectedCount })
      : t('tableSettings:hiddenFields', { count: deselectedCount })
    : viewMode === ViewMode.List
      ? t('tableSettings:allColumnsVisible')
      : t('tableSettings:allFieldsVisible');

  const trigger = (
    <div className={style.triggerContainer}>
      <Tooltip
        content={hiddenColumnsTooltipContent}
        delay={[100, 300]}
        visible={isTooltipOpen}>
        <div>
          <Tooltip
            delay={[300, 100]}
            content={tooltipContent}>
            <Icon
              name='Settings'
              className={style.dropdownTrigger}
            />
          </Tooltip>
        </div>
      </Tooltip>

      {deselectedCount ? (
        <Icon
          name='EyeClosed'
          className={style.eyeClosedIcon}
        />
      ) : null}
    </div>
  );

  return (
    <Dropdown
      width='normal'
      onToggle={resetForm}
      popperClassName={style.popper}
      trigger={trigger}
      position={['bottom', 'right']}>
      <Header variant='h4' className={style.popperTitle}>
        {title || t('tableSettings:displayOptions')}
      </Header>
      <form
        className={style.form}
        onSubmit={onSubmit}>
        {renderOptions()}
        <div className={style.footer}>
          <Dropdown.CloseHandler>{
            (closeMenu) => (
              <Button
                sizeVariant='small'
                variant='secondary'
                onClick={() => resetColumnsAndCloseMenu(closeMenu)}>
                {t('tableSettings:resetToDefault')}
              </Button>
            )
          }
          </Dropdown.CloseHandler>
          <Dropdown.CloseHandler>{
            (closeMenu) => {
              return (
                <Button
                  sizeVariant='small'
                  type='submit'
                  className={style.saveButton}
                  onClick={closeMenu}>
                  {t('common:save')}
                </Button>
              );
            }
          }
          </Dropdown.CloseHandler>
        </div>
      </form>
    </Dropdown>
  );
};
