import { useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { SectionHeader } from '@/components/shared/SectionHeader';
import { Pagination } from '@/components/pagination/Pagination';
import { WithQueryLoader } from '@/components/shared/WithQueryLoader';
import { EmptyCollection } from '@/components/shared/EmptyCollection';
import { Button } from '@/components/shared/buttons';
import { Body } from '@/components/shared/typography/Body';
import { ResellerStoreListingsList } from '@/components/resellers/ResellerStoreListings/ResellerStoreListingsList';
import { ResellerStoreListingsToolbar } from '@/components/resellers/ResellerStoreListings/ResellerStoreListingsToolbar';
import { IconBox } from '@/components/shared/IconBox';
import { Tooltip } from '@/components/shared/Tooltip';
import type { FilterEntryProps } from '@/components/shared/FilterEntry';
import { StoreListingsGrid } from '@/components/store/StoreListingsGrid';
import emptyListing from '@/assets/images/empty_listing.svg';
import { usePaginationParams } from '@/hooks/shared/usePaginationParams';
import { useResellerStoresListingsParams } from '@/hooks/resellers';
import { EMPTY_ARRAY } from '@/resources/constants';
import {
  StoreStatus,
  ViewMode,
} from '@/resources/enums';
import {
  useListings,
  UseListingsParams,
  useListingsSelectionParams,
  useListingsSortParams,
} from '@/hooks/listing';
import { parens } from '@/utils/string';
import { useViewModeContext } from '@/contexts/shared';
import { useUserRole } from '@/hooks/companyUser';
import { useIsTrademarkAddedToSubdomain } from '@/hooks/trademark';
import { useActiveLoaCheck } from '@/hooks/loa/useActiveLoaCheck';
import { useBranding } from '@/hooks/branding';
import { useEnforcementPolicies } from '@/hooks/enforcementPolicy';
import type { ResellerStore } from '@/types/reseller';
import type { Store } from '@/types/store';

import style from './ResellerStoreListings.module.sass';
import {
  useFilteredListingsQueriesMapping,
  useOverrideNotification,
  useRequestCreateEnforcement,
  useRequestCreateTestPurchase,
} from './hooks';
import { getTooltipMessageForUncreatableAction } from './utils';

type ResellerStoreListingsProps = {
  store: ResellerStore | Store;
  filterEntriesVariant?: FilterEntryProps['variant'];
};

export const ResellerStoreListings = ({
  store,
  filterEntriesVariant,
}: ResellerStoreListingsProps) => {
  const { t } = useTranslation();

  const overrideNotification = useOverrideNotification();
  const { isGuest } = useUserRole();

  const {
    branding,
    isLoading: isBrandingLoading,
    error: brandingError,
  } = useBranding();

  const [selectedListingIds, setSelectedListingIds] = useListingsSelectionParams();

  const {
    page,
    perPage,
  } = usePaginationParams(1, 10);

  const {
    sortBy,
  } = useListingsSortParams();

  const {
    values,
  } = useResellerStoresListingsParams();

  const resetSelectedListingIds = useCallback(() => {
    setSelectedListingIds(EMPTY_ARRAY);
  }, [
    setSelectedListingIds,
  ]);

  const paginationParams = { page, perPage };
  const sortParams = { sort: sortBy };

  const areAnyListingsSelected = selectedListingIds?.length > 0;

  const {
    isTrademarkAddedToSubdomain,
    isLoading: isIsTrademarkAddedToSubdomainLoading,
    error: trademarkAddedToSubdomainError,
  } = useIsTrademarkAddedToSubdomain(store.linked?.subdomain?.id);

  const {
    activeLoa,
    isLoading: isActiveLoaLoading,
    error: activeLoaError,
  } = useActiveLoaCheck(branding?.attributes.companyId);

  const {
    enforcementPolicies,
    isLoading: isEnforcementPoliciesLoading,
    error: enforcementPoliciesError,
  } = useEnforcementPolicies({
    filter: {
      subdomainId: store.linked?.subdomain?.id,
      isActive: 'true',
    },
  });

  const paramsConfig: UseListingsParams = {
    filter: {
      'store.id': store.id,
      age: values.age,
      brandId: values.brandId,
      productName: values.productName,
      itemNumber: values.itemNumber,
      isNew: values.isNew,
    },
    pagination: paginationParams,
    sort: sortParams,
  };

  const {
    listings,
    isLoading: isListingsLoading,
    error: listingsError,
    meta,
  } = useListings(paramsConfig);

  const {
    dataMapping,
    isLoading: isMetaMappingLoading,
    error: metaMappingError,
  } = useFilteredListingsQueriesMapping(paramsConfig);

  const {
    viewMode,
  } = useViewModeContext();

  const selectedListings = useMemo(() => {
    return listings?.filter((listing) => {
      return selectedListingIds.includes(listing.id);
    });
  }, [
    selectedListingIds,
    listings,
  ]);

  const requestCreateEnforcement = useRequestCreateEnforcement({
    selectedListings: selectedListings,
    onAfterNotificationCreate: overrideNotification,
    storeId: store.id,
  });

  const requestCreateTestPurchase = useRequestCreateTestPurchase({
    selectedListings: selectedListings,
    onAfterNotificationCreate: overrideNotification,
    storeId: store.id,
  });

  const renderStoreListings = () => {
    if (!listings?.length) {
      return (
        <EmptyCollection
          imageSource={emptyListing}
          header={t('store:listingsList.noListings')}
          imageSizeVariant='medium'
        />
      );
    }

    const isSelectionAllowed = (
      !isGuest
      &&
      store.attributes.status !== StoreStatus.Allowed
    );

    if (ViewMode.List === viewMode) {
      return (
        <ResellerStoreListingsList
          storeStatus={store.attributes.status}
          listings={listings}
          isSelectionAllowed={isSelectionAllowed}
        />
      );
    }

    return (
      <StoreListingsGrid
        storeStatus={store.attributes.status}
        listings={listings}
        isSelectionAllowed={isSelectionAllowed}
      />
    );
  };

  const renderViewToolbar = () => {
    if (!store?.attributes.listingsCount) {
      return null;
    }

    return (
      <ResellerStoreListingsToolbar
        totalEntries={meta?.pagination.totalEntries}
        filterValues={values}
        disableFilters={isMetaMappingLoading}
        aggregationMapping={{
          age: dataMapping.age?.meta?.aggregation.age,
          brand: dataMapping.brand?.meta?.aggregation.brand,
          condition: dataMapping.condition?.meta?.aggregation.isNew,
        }}
        filterEntriesVariant={filterEntriesVariant}
      />
    );
  };

  const renderAsideActions = () => {
    if (isGuest) {
      return null;
    }

    if (store.attributes.status === StoreStatus.Allowed) {
      return (
        <Tooltip
          content={t('store:actions.allowedStatusActionsInfo')}>
          <IconBox
            wrapperClassName={style.infoIcon}
            iconName='Info'
          />
        </Tooltip>
      );
    }

    const renderCreateEnforcementButton = () => {
      const hasActiveLoa = Boolean(activeLoa?.attributes.letterOfAuthorisationId);
      const hasSubdomainActivePolicyEnforcement = Boolean(enforcementPolicies?.length);
      const canCreateAction = (
        isTrademarkAddedToSubdomain && hasActiveLoa && hasSubdomainActivePolicyEnforcement
      );

      if (!canCreateAction) {
        const tooltipText = getTooltipMessageForUncreatableAction({
          isTrademarkAddedToSubdomain,
          hasActiveLoa,
          hasSubdomainActivePolicyEnforcement,
          t,
        });

        return (
          <Tooltip
            sizeVariant='base'
            disabled={!areAnyListingsSelected}
            content={tooltipText}>
            <div>
              <Button
                variant='primary'
                disabled={true}>
                {t('store:listingsList.createEnforcement')}
              </Button>
            </div>
          </Tooltip>
        );
      }

      return (
        <Button
          variant='primary'
          onClick={requestCreateEnforcement}
          disabled={!areAnyListingsSelected}>
          {t('store:listingsList.createEnforcement')}
        </Button>
      );
    };

    return (
      <SectionHeader.AsideActions>
        {
          areAnyListingsSelected &&
          <Body size='base' className={style.selectedListingsCount}>
            {t('store:listingsList.selectedListingsCount', { count: selectedListingIds.length })}
          </Body>
        }
        <Tooltip
          disabled={areAnyListingsSelected}
          content={t('store:listingsList.selectListings')}>
          <div className={style.asideButtons}>
            <Button
              variant='secondary'
              onClick={requestCreateTestPurchase}
              disabled={!areAnyListingsSelected}>
              {t('store:listingsList.createTestPurchase')}
            </Button>
            {renderCreateEnforcementButton()}
          </div>
        </Tooltip>
      </SectionHeader.AsideActions>
    );
  };

  const isLoading = isListingsLoading
    || isMetaMappingLoading
    || isIsTrademarkAddedToSubdomainLoading
    || isActiveLoaLoading
    || isBrandingLoading
    || isEnforcementPoliciesLoading;

  const error = listingsError
    || metaMappingError
    || trademarkAddedToSubdomainError
    || activeLoaError
    || brandingError
    || enforcementPoliciesError;

  return (
    <>
      <SectionHeader
        title={t('store:listingsList.listings')}
        subtitle={parens(store?.attributes.listingsCount)}
        className={style.headerWrapper}
        headerSizeVariant='h4'>
        {renderAsideActions()}
      </SectionHeader>
      {renderViewToolbar()}
      <WithQueryLoader
        isLoading={isLoading}
        error={error}>
        {renderStoreListings()}
        {
          meta &&
          <Pagination
            initialPerPage={10}
            itemsCount={listings?.length}
            totalItemsCount={meta.pagination.totalEntries}
            pages={meta.pagination.totalPages}
            wrapperClassName={style.paginationWrapper}
            onAfterChangePage={resetSelectedListingIds}
            onAfterChangePerPage={resetSelectedListingIds}
          />
        }
      </WithQueryLoader>
    </>
  );
};
