import { TFunction } from 'i18next';

import { StoreStatus } from '@/resources/enums';
import { IconName } from '@/types/shared';

export const getListingStoreStatusIcon = (
  status?: StoreStatus,
): IconName | undefined => {
  switch (status) {
    case StoreStatus.ActionInProgress:
      return 'ActionInProgress';
    case StoreStatus.Allowed:
      return 'Allowlist';
    case StoreStatus.Greylisted:
      return 'Greylist';
    default:
      return;
  }
};

export const getProductConditionTranslationKey = (
  t: TFunction,
  isNew: boolean | null | string,
): string => {
  if (typeof isNew === 'string') {
    return t(`listing:filterable.productCondition.${isNew === 'true' ? 'new' : isNew === 'false' ? 'other' : 'unknown'}`);
  }

  if (isNew === true) return t('listing:filterable.productCondition.new');
  if (isNew === false) return t('listing:filterable.productCondition.other');

  return t('listing:filterable.productCondition.unknown');
};
