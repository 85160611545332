import { TableOption, UseSelectedColumnsHook, TableSettings } from '@/components/shared/TableSettings/TableSettings';
import { useViewModeContext } from '@/contexts/shared';

type TableSettingsWithViewModeProps = {
  options: TableOption[];
  useSelectedColumns: UseSelectedColumnsHook;
}

export const TableSettingsWithViewMode = ({
  options,
  useSelectedColumns,
}: TableSettingsWithViewModeProps) => {
  const {
    viewMode,
  } = useViewModeContext();

  return (
    <TableSettings
      viewMode={viewMode}
      options={options}
      useSelectedColumns={useSelectedColumns}
    />
  );
};

