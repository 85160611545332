import { useCallback, useMemo } from 'react';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import { StyledLink } from '@/components/shared/StyledLink';
import { SelectInput } from '@/components/shared/form/inputs/SelectInput';
import { FieldRenderer } from '@/components/shared/form/FieldRenderer';
import { DebouncedTextInput } from '@/components/shared/form/inputs/DebouncedTextInput';
import { fillValues, hasAnyNonNullish } from '@/utils/object';
import { useStoresFilterParams } from '@/hooks/stores';
import { useBuildClearValue, useIsOnInstance } from '@/hooks/shared';
import {
  BRAND_ID,
  COUNTRY_ID,
  EMAIL_PRESENCE,
  NAME,
  PRODUCT_CONDITION,
  STATUS,
  SUBDOMAIN_ID,
} from '@/resources/queryParams';
import { getSubdomainOptions, getCountryOptions, getStoreStatusOptions, getEmailOptions, getCompanyOptions, getProductConditionOptions } from '@/utils/filters';
import { getBrandOptions } from '@/utils/filters/brand';
import type { Channel } from '@/types/channel';
import type { StoresFilterParams } from '@/types/store';
import type { AggregationEntry } from '@/types/shared';

import style from './StoresFilters.module.sass';

type StoresFiltersAggregationMapping = {
  subdomain?: AggregationEntry[];
  country?: AggregationEntry[];
  status?: AggregationEntry[];
  email?: AggregationEntry[];
  brand?: AggregationEntry[];
  company?: AggregationEntry[];
  condition?: AggregationEntry[];
};

export type StoresFiltersProps = {
  channels: Channel[];
  aggregationMapping?: StoresFiltersAggregationMapping;
  disabled?: boolean;
};

export const StoresFilters = ({
  channels,
  aggregationMapping,
  disabled,
}: StoresFiltersProps) => {
  const { t } = useTranslation();
  const buildClearValue = useBuildClearValue<StoresFilterParams>();
  const { isAdminInstance } = useIsOnInstance();

  const {
    values,
  } = useStoresFilterParams();

  const {
    setValues,
  } = useFormikContext();

  const resetFilterValues = useCallback(() => {
    const emptyValues = fillValues(values, undefined);

    setValues(emptyValues);
  }, [
    values,
    setValues,
  ]);

  const isClearButtonShown = hasAnyNonNullish(values, { ignore: [''] });

  const subdomainAgg = aggregationMapping?.subdomain;
  const countryAgg = aggregationMapping?.country;
  const statusAgg = aggregationMapping?.status;
  const emailAgg = aggregationMapping?.email;
  const brandAgg = aggregationMapping?.brand;

  const channelOptions = useMemo(() => {
    return getSubdomainOptions({
      channels,
      subdomainAgg,
    });
  }, [
    channels,
    subdomainAgg,
  ]);

  const countryOptions = useMemo(() => {
    if (!countryAgg) {
      return;
    }

    return getCountryOptions({
      countryAgg,
      optionWrapperClassName: style.option,
    });
  }, [
    countryAgg,
  ]);

  const statusOptions = useMemo(() => {
    if (!statusAgg) {
      return;
    }

    return getStoreStatusOptions({
      t,
      statusAgg,
      optionWrapperClassName: style.option,
    });
  }, [
    t,
    statusAgg,
  ]);

  const emailOptions = useMemo(() => {
    return getEmailOptions({
      t,
      emailAgg,
      optionWrapperClassName: style.option,
    });
  }, [
    t,
    emailAgg,
  ]);

  const brandOptions = useMemo(() => {
    return getBrandOptions({
      t,
      brandAgg,
    });
  }, [
    brandAgg,
    t,
  ]);

  const companyOptions = useMemo(() => {
    if (!aggregationMapping?.company) return;

    return getCompanyOptions({
      companyAgg: aggregationMapping.company,
    });
  }, [
    aggregationMapping?.company,
  ]);

  const conditionAgg = aggregationMapping?.condition;

  const productConditionOptions = useMemo(() => {
    return getProductConditionOptions({
      conditionAgg,
      t,
      optionWrapperClassName: style.option,
    });
  }, [
    conditionAgg,
    t,
  ]);

  const renderStatusOrEmailField = () => {
    if (isAdminInstance) {
      return (
        <FieldRenderer
          name={EMAIL_PRESENCE}
          Component={SelectInput}
          popperAlign='left'
          className={style.filterSelect}
          placeholder={t('store:filterable.placeholders.email')}
          optionVariant='radio'
          options={emailOptions}
          popperTitle={t('store:filterable.titles.email')}
          deselectable={true}
          disabled={disabled}
        />
      );
    }

    return (
      <FieldRenderer
        name={STATUS}
        Component={SelectInput}
        popperAlign='left'
        className={style.filterSelect}
        placeholder={t('store:filterable.placeholders.status')}
        optionVariant='radio'
        options={statusOptions}
        popperTitle={t('store:filterable.titles.status')}
        deselectable={true}
        disabled={disabled}
      />
    );
  };

  const renderBrandFilter = () => {
    if (isAdminInstance) {
      return null;
    }

    return (
      <FieldRenderer
        name={BRAND_ID}
        Component={SelectInput}
        popperAlign='left'
        className={style.filterSelect}
        placeholder={t('store:filterable.placeholders.brand')}
        optionsListClassName={style.channelOptionsList}
        optionVariant='radio'
        options={brandOptions}
        popperTitle={t('store:filterable.titles.brand')}
        deselectable={true}
        disabled={disabled}
      />
    );
  };

  const renderCompanyFilter = () => {
    if (!isAdminInstance) {
      return null;
    }

    return (
      <FieldRenderer
        name='companyId'
        Component={SelectInput}
        popperAlign='left'
        className={style.filterSelect}
        optionsClassName={style.companyFilterOptions}
        placeholder={t('store:filterable.placeholders.companyId')}
        optionVariant='radio'
        options={companyOptions}
        popperTitle={t('store:filterable.titles.companyId')}
        deselectable={true}
        disabled={disabled}
      />
    );
  };

  const renderProductConditionFilter = () => {
    if (isAdminInstance) {
      return null;
    }

    return (
      <FieldRenderer
        name={PRODUCT_CONDITION}
        Component={SelectInput}
        popperAlign='left'
        className={style.filterSelect}
        placeholder={t('store:filterable.placeholders.productCondition')}
        optionVariant='radio'
        options={productConditionOptions}
        popperTitle={t('store:filterable.titles.productCondition')}
        deselectable={true}
        disabled={disabled}
        wrapperClassName={style.filterSelectWrapper}
      />
    );
  };

  return (
    <div className={style.filtersWrapper}>
      <div className={style.nameFilterWrapper}>
        <FieldRenderer
          name={NAME}
          Component={DebouncedTextInput}
          clearable={true}
          debounceMs={500}
          sizeVariant='small'
          iconName='Search'
          onClear={buildClearValue(NAME)}
          placeholder={t('store:filterable.placeholders.productName')}
          disabled={disabled}
        />
      </div>
      <FieldRenderer
        name={SUBDOMAIN_ID}
        Component={SelectInput}
        popperAlign='left'
        className={style.filterSelect}
        placeholder={t('store:filterable.placeholders.subdomainId')}
        optionVariant='radio'
        options={channelOptions}
        popperTitle={t('store:filterable.titles.subdomainId')}
        deselectable={true}
        disabled={disabled}
        optionsListClassName={style.channelOptionsList}
      />
      {renderCompanyFilter()}
      {renderBrandFilter()}
      {renderProductConditionFilter()}
      <FieldRenderer
        name={COUNTRY_ID}
        Component={SelectInput}
        popperAlign='left'
        className={style.filterSelect}
        placeholder={t('store:filterable.placeholders.countryId')}
        optionVariant='radio'
        options={countryOptions}
        popperTitle={t('store:filterable.titles.countryId')}
        deselectable={true}
        disabled={disabled}
      />
      {renderStatusOrEmailField()}
      {
        isClearButtonShown &&
        <div className={style.clearWrapper}>
          <StyledLink onClick={resetFilterValues}>
            {t('common:filter.clearFilter')}
          </StyledLink>
        </div>
      }
    </div>
  );
};
