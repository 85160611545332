import { useNavigate } from 'react-router-dom';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import useUnmount from 'react-use/esm/useUnmount';

import { VerticalTable } from '@/components/shared/tables/VerticalTable';
import { Body } from '@/components/shared/typography/Body';
import { StyledLinkExternal } from '@/components/shared/StyledLinkExternal';
import { BotRunStatusInfo } from '@/components/bot/BotRunStatusInfo';
import { BotRunTime } from '@/components/bot/BotRunTime';
import { BotSubdomain } from '@/components/bot/BotSubdomain';
import { BotRunRowsCount } from '@/components/bot/BotRunRowsCount';
import { type CardContextMenuProps } from '@/components/shared/Card/CardContextMenu';
import { MultilineHeader } from '@/components/shared/MultilineHeader';
import { BrandGraphic } from '@/components/brand/BrandGraphic';
import { useMediaQuery, useUnsafeContext } from '@/hooks/shared';
import { DeleteBotContext } from '@/contexts/company';
import { useRoutePathContext } from '@/contexts/shared';
import { useResourceSelectIds } from '@/hooks/zustsand/resourceSelectIds';
import { useBotsColumns } from '@/hooks/bot';
import { BOTS_SELECTION_KEY } from '@/resources/bot';
import {
  SUBDOMAIN_ID,
  BRAND_ID,
  LATEST_RUN,
} from '@/resources/queryParams';
import type { Bot } from '@/types/bot';
import type { ID } from '@/types/shared';
import type { TableConfig } from '@/types/tables';

import style from './BotsList.module.sass';

type BotsListProps = {
  bots: Bot[];
  contextMenu?: (bot: Bot) => CardContextMenuProps;
  getCellClassName?: () => string;
  hasBrandLink?: boolean;
  shouldHideHeader?: boolean;
  isCompanyHidden?: boolean;
  isRunHidden?: boolean;
};

export const BotsList = ({
  bots,
  contextMenu,
  getCellClassName,
  hasBrandLink = true,
  shouldHideHeader,
  isCompanyHidden,
  isRunHidden,
}: BotsListProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { selectedColumns } = useBotsColumns();
  const {
    closeModal,
  } = useUnsafeContext(DeleteBotContext);

  const {
    setIsSelecting,
    isSelecting,
    ids,
    setIds,
  } = useResourceSelectIds(BOTS_SELECTION_KEY);

  useUnmount(() => {
    closeModal();
    setIsSelecting(false);
  });

  const {
    getInstanceBotRoute,
  } = useRoutePathContext();

  const navigateToBot = useCallback((id: ID) => {
    navigate(getInstanceBotRoute(id));
  }, [
    getInstanceBotRoute,
    navigate,
  ]);

  const isDesktopHd = useMediaQuery('(min-width: 1920px)');

  const restTableConfig = useCallback(() => ({
    shouldHideHeader,
    getContextMenuProps: contextMenu,
    onRowClick: isSelecting ? undefined : (bot) => navigateToBot(bot.id),
    getResourceRoute: isSelecting ? undefined : (bot) => getInstanceBotRoute(bot.id),
    getCellClassName,
    selectable: isSelecting
      ? {
        onSelect: setIds,
        selectedIds: ids,
      }
      : undefined,
  }) as Omit<TableConfig<Bot>, 'columns'>, [
    contextMenu,
    getCellClassName,
    getInstanceBotRoute,
    ids,
    isSelecting,
    navigateToBot,
    setIds,
    shouldHideHeader,
  ]);

  const tableConfig = useMemo<TableConfig<Bot>>(() => ({
    columns: [
      {
        header: t('bot:attributes.channel'),
        colSpan: 2,
        render: (bot) => (
          <BotSubdomain
            name={bot.linked?.channel?.attributes.name}
            url={bot.linked?.subdomain?.attributes.url}
            isWrappedToNextLine={true}
          />
        ),
        hidden: !selectedColumns.includes(SUBDOMAIN_ID),
      },
      {
        header: t('bot:attributes.name'),
        colSpan: 6,
        render: (bot) => (
          <div className={style.botInfoWrapper}>
            <MultilineHeader
              variant={isDesktopHd ? 'h4' : 'h5'}
              className={style.botName}>
              {bot.attributes.name}
            </MultilineHeader>
            <StyledLinkExternal
              href={bot.attributes.searchTerm}
              className={style.botLink}>
              {bot.attributes.searchTerm}
            </StyledLinkExternal>
          </div>
        ),
      },
      {
        header: t('bot:attributes.companyName'),
        colSpan: 3,
        className: style.companyColumn,
        render: (bot) => (
          <Body size='base'>
            {bot.linked?.company?.attributes.name}
          </Body>
        ),
        hidden: isCompanyHidden,
      },
      {
        header: t('bot:attributes.brand'),
        colSpan: 3,
        render: (bot) => {
          const brandId = hasBrandLink ? bot?.linked?.brand?.id : undefined;
          const companyId = hasBrandLink ? bot?.linked?.company?.id : undefined;

          return (
            <BrandGraphic
              brandId={brandId}
              companyId={companyId}
              logoUrl={bot?.linked?.brand?.attributes.logoUrl}
              name={bot?.linked?.brand?.attributes.name}
              imageSizeVariant='small'
              nameSizeVariant='small'
            />
          );
        },
        hidden: !selectedColumns.includes(BRAND_ID),
      },
      {
        header: t('bot:attributes.latestRun'),
        colSpan: 4,
        render: (bot) => {
          return (
            <div className={style.latestRunWrapper}>
              <div className={style.latestRun}>
                <BotRunStatusInfo
                  status={bot.linked?.latestRun?.attributes.status}
                  isSlow={bot.linked?.latestRun?.attributes.slow}
                />
                <div className={style.rowsCount}>
                  <BotRunRowsCount
                    count={bot.linked?.latestRun?.attributes.numRowsAccepted}
                  />
                </div>
                <BotRunTime
                  time={bot.linked?.latestRun?.attributes.startedAt}
                  textClassName={style.botRunTime}
                />
              </div>
            </div>
          );
        },
        hidden: isRunHidden || !selectedColumns.includes(LATEST_RUN),
      },
    ],
    ...restTableConfig(),
  }), [
    t,
    isCompanyHidden,
    isRunHidden,
    restTableConfig,
    isDesktopHd,
    hasBrandLink,
    selectedColumns,
  ]);

  return (
    <VerticalTable
      config={tableConfig}
      data={bots}
    />
  );
};
