import { useTranslation } from 'react-i18next';

import { TableSettingsWithViewMode } from '@/components/shared/TableSettingsWithViewMode';
import { useBotsColumns } from '@/hooks/bot';
import {
  NAME,
  LATEST_RUN,
  SUBDOMAIN_ID,
  COMPANY_ID,
  BRAND_ID,
} from '@/resources/queryParams';

export const BotsListTableSettings = () => {
  const { t } = useTranslation();

  const options = [
    { id: SUBDOMAIN_ID, label: t('bot:attributes.channel') },
    { id: NAME, label: t('bot:attributes.name'), disabled: true },
    { id: COMPANY_ID, label: t('bot:attributes.company'), disabled: true },
    { id: BRAND_ID, label: t('bot:attributes.brand') },
    { id: LATEST_RUN, label: t('bot:attributes.latestRun') },
  ];

  return (
    <TableSettingsWithViewMode
      options={options}
      useSelectedColumns={useBotsColumns()}
    />
  );
};
