/* eslint-disable max-len */
import { useParams } from 'react-router-dom';

import {
  ROUTE_PATH_CONTEXT_DEFAULT_STATE,
  RoutePathContextValue,
} from '@/contexts/shared/RoutePathContext';
import { useBranding } from '@/hooks/branding';
import * as routes from '@/utils/routes';
import { RouteParam } from '@/types/shared';

import { useIsOnInstance } from './useIsOnInstance';

export const useGetInstanceRoute = (): RoutePathContextValue => {
  const {
    isAdminInstance,
    isCustomerInstance,
  } = useIsOnInstance();

  const { companyId } = useParams();
  const { branding } = useBranding();

  const customerCompanyId = branding?.attributes.companyId;

  const areCustomerRoutes = customerCompanyId && isCustomerInstance;
  const areGsCompanyScopedRoutes = companyId && isAdminInstance;

  if (areCustomerRoutes) {
    return {
      ...ROUTE_PATH_CONTEXT_DEFAULT_STATE,
      getInstanceUsersRoute: routes.getAdminUsersRoute,
      getInstanceUserRoute: routes.getAdminUserRoute,
      getInstanceUserCreateRoute: routes.getAdminCreateUserRoute,
      getInstanceUserEditRoute: routes.getAdminEditUserRoute,
      getInstanceUserDeleteRoute: routes.getAdminDeleteUserRoute,

      getInstanceBotsRoute: routes.getAdminBotsRoute,
      getInstanceBotRoute: routes.getAdminBotRoute,
      getInstanceBotCreateRoute: routes.getAdminCreateBotRoute,
      getInstanceBotCreateMultipleRoute: routes.getAdminCreateMultipleBotsRoute,
      getInstanceBotEditRoute: routes.getAdminEditBotRoute,
      getInstanceBotEditMultipleRoute: routes.getAdminEditMultipleBotRoute,
      getInstanceBotDeleteRoute: routes.getAdminDeleteBotRoute,

      getInstanceTrademarksRoute: routes.getAdminTrademarksRoute,
      getInstanceTrademarkRoute: (_, id: RouteParam) => routes.getAdminTrademarkRoute(id),
      getInstanceTrademarkCreateRoute: routes.getAdminCreateTrademarkRoute,
      getInstanceTrademarkEditRoute: (_, id: RouteParam) => routes.getAdminEditTrademarkRoute(id),
      getInstanceTrademarkDeleteRoute: (_, id: RouteParam) => routes.getAdminDeleteTrademarkRoute(id),

      getInstanceKeywordsRoute: routes.getAdminKeywordsRoute,
      getInstanceKeywordRoute: routes.getAdminKeywordRoute,
      getInstanceKeywordCreateMultipleRoute: routes.getAdminCreateMultipleKeywordsRoute,
      getInstanceKeywordEditRoute: routes.getAdminEditKeywordRoute,
      getInstanceKeywordDeleteRoute: routes.getAdminDeleteKeywordRoute,

      getInstanceCompanyRoute: routes.getAdminRoute,
      getInstanceCompanyEditRoute: routes.getAdminEditCompanyRoute,

      getInstanceLoasRoute: routes.getAdminLoasRoute,
      getInstanceLoaRoute: routes.getAdminLoaRoute,
      getInstanceLoaDownloadToSignRoute: routes.getAdminDownloadToSignLoaRoute,
      getInstanceLoaUploadSignedRoute: routes.getAdminUploadSignedLoaRoute,
      getInstanceLoaCreateRoute: routes.getAdminCreateLoaRoute,
      getInstanceLoaEditRoute: routes.getAdminEditLoaRoute,
      getInstanceLoaDuplicateRoute: routes.getAdminDuplicateLoaRoute,

      getInstanceBrandsRoute: routes.getAdminBrandsRoute,
      getInstanceBrandRoute: routes.getAdminBrandRoute,
      getInstanceBrandCreateRoute: routes.getAdminCreateBrandRoute,
      getInstanceBrandEditRoute: routes.getAdminEditBrandRoute,
      getInstanceBrandDeleteRoute: routes.getAdminDeleteBrandRoute,

      getInstanceEnforcementPoliciesRoute: routes.getAdminEnforcementPoliciesRoute,
      getInstanceEnforcementPolicyRoute: routes.getAdminEnforcementPolicyRoute,
      getInstanceCreateEnforcementPolicyRoute: routes.getAdminCreateEnforcementPolicyRoute,
      getInstanceEditEnforcementPolicyRoute: (id: RouteParam) => routes.getAdminEditEnforcementPolicyRoute(id),
    };
  }

  if (areGsCompanyScopedRoutes) {
    return {
      getInstanceUsersRoute: () => routes.getCompanyUsersRoute(companyId),
      getInstanceUserRoute: (id: RouteParam) => routes.getCompanyUserRoute(companyId, id),
      getInstanceUserCreateRoute: () => routes.getCompanyCreateUserRoute(companyId),
      getInstanceUserEditRoute: (id: RouteParam) => routes.getCompanyEditUserRoute(companyId, id),
      getInstanceUserDeleteRoute: (id: RouteParam) => routes.getCompanyDeleteUserRoute(companyId, id),

      getInstanceBotsRoute: () => routes.getCompanyBotsRoute(companyId),
      getInstanceBotRoute: (id: RouteParam) => routes.getCompanyBotRoute(companyId, id),
      getInstanceBotCreateRoute: () => routes.getCreateCompanyBotRoute(companyId),
      getInstanceBotCreateMultipleRoute: () => routes.getCreateMultipleCompanyBotsRoute(companyId),
      getInstanceBotEditRoute: (id: RouteParam) => routes.getEditCompanyBotRoute(companyId, id),
      getInstanceBotEditMultipleRoute: () => routes.getCompanyEditMultipleBotsRoute(companyId),
      getInstanceBotDeleteRoute: (id: RouteParam) => routes.getDeleteCompanyBotRoute(companyId, id),
      getInstanceBotRunRoute: routes.getBotRunRoute,

      getInstanceTrademarksRoute: routes.getCompanyTrademarksRoute,
      getInstanceTrademarkRoute: routes.getCompanyTrademarkRoute,
      getInstanceTrademarkCreateRoute: routes.getCreateCompanyTrademarkRoute,
      getInstanceTrademarkEditRoute: routes.getEditCompanyTrademarkRoute,
      getInstanceTrademarkDeleteRoute: routes.getDeleteCompanyTrademarkRoute,

      getInstanceKeywordsRoute: () => routes.getKeywordsRoute(companyId),
      getInstanceKeywordRoute: (id: RouteParam) => routes.getKeywordRoute(companyId, id),
      getInstanceKeywordCreateMultipleRoute: () => routes.getCreateMultipleKeywordsRoute(companyId),
      getInstanceKeywordEditRoute: (id: RouteParam) => routes.getEditKeywordRoute(companyId, id),
      getInstanceKeywordDeleteRoute: (id: RouteParam) => routes.getDeleteKeywordRoute(companyId, id),

      getInstanceCompanyRoute: routes.getCompanyRoute,
      getInstanceCompanyEditRoute: routes.getEditCompanyRoute,
      getInstanceCompanyDeleteRoute: routes.getDeleteCompanyRoute,

      getInstanceLoasRoute: () => routes.getCompanyLoasRoute(companyId),
      getInstanceLoaRoute: (id: RouteParam) => routes.getCompanyLoaRoute(companyId, id),
      getInstanceLoaDownloadToSignRoute: (id: RouteParam) => routes.getCompanyDownloadToSignLoaRoute(companyId, id),
      getInstanceLoaUploadSignedRoute: (id: RouteParam) => routes.getCompanyUploadSignedLoaRoute(companyId, id),
      getInstanceLoaCreateRoute: () => routes.getCreateCompanyLoaRoute(companyId),
      getInstanceLoaEditRoute: (id: RouteParam) => routes.getCompanyEditLoaRoute(companyId, id),
      getInstanceLoaDuplicateRoute: (id: RouteParam) => routes.getCompanyDuplicateLoaRoute(companyId, id),

      getInstanceBrandsRoute: () => routes.getCompanyBrandsRoute(companyId),
      getInstanceBrandRoute: (id: RouteParam) => routes.getCompanyBrandRoute(companyId, id),
      getInstanceBrandCreateRoute: () => routes.getCompanyCreateBrandRoute(companyId),
      getInstanceBrandEditRoute: (id: RouteParam) => routes.getCompanyEditBrandRoute(companyId, id),
      getInstanceBrandDeleteRoute: (id: RouteParam) => routes.getCompanyDeleteBrandRoute(companyId, id),

      getInstanceEnforcementPoliciesRoute: () => routes.getCompanyEnforcementPoliciesRoute(companyId),
      getInstanceEnforcementPolicyRoute: (id: RouteParam) => routes.getCompanyEnforcementPolicyRoute(companyId, id),
      getInstanceCreateEnforcementPolicyRoute: () => routes.getCreateCompanyEnforcementPolicyRoute(companyId),
      getInstanceEditEnforcementPolicyRoute: (id: RouteParam) => routes.getEditCompanyEnforcementPolicyRoute(companyId, id),
    };
  }

  return {
    ...ROUTE_PATH_CONTEXT_DEFAULT_STATE,
    getInstanceBotsRoute: routes.getBotsRoute,
    getInstanceBotRoute: routes.getBotRoute,
    getInstanceBotCreateRoute: routes.getCreateBotRoute,
    getInstanceBotCreateMultipleRoute: routes.getCreateMultipleBotsRoute,
    getInstanceBotEditRoute: routes.getEditBotRoute,
    getInstanceBotDeleteRoute: routes.getDeleteBotRoute,
    getInstanceBotRunRoute: routes.getBotRunRoute,

    getInstanceTrademarksRoute: routes.getCompanyTrademarksRoute,
    getInstanceTrademarkRoute: routes.getCompanyTrademarkRoute,
    getInstanceTrademarkCreateRoute: routes.getCreateCompanyTrademarkRoute,
    getInstanceTrademarkEditRoute: routes.getEditCompanyTrademarkRoute,
    getInstanceTrademarkDeleteRoute: routes.getDeleteCompanyTrademarkRoute,

    getInstanceCompanyRoute: routes.getCompanyRoute,
    getInstanceCompanyEditRoute: routes.getEditCompanyRoute,
    getInstanceCompanyDeleteRoute: routes.getDeleteCompanyRoute,

    getInstanceBrandRoute: (id: RouteParam, overwrittenCompanyId: RouteParam) => routes.getCompanyBrandRoute(overwrittenCompanyId, id),
    getInstanceBotEditMultipleRoute: routes.getEditMultipleBotsRoute,
  };
};
