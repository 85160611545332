import { MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames/bind';

import { Card } from '@/components/shared/Card';
import { ImageWithFallback } from '@/components/shared/ImageWithFallback';
import { Body } from '@/components/shared/typography/Body';
import { ListingPrice } from '@/components/shared/Listing/ListingPrice';
import { ListingEnforcementIndicator } from '@/components/listing/ListingEnforcementIndicator';
import { ListingTestPurchaseIndicator } from '@/components/listing/ListingTestPurchaseIndicator';
import { MultilineHeader } from '@/components/shared/MultilineHeader';
import { CheckboxInput } from '@/components/shared/form/inputs/CheckboxInput';
import { StyledLink } from '@/components/shared/StyledLink';
import { BrandGraphic } from '@/components/brand/BrandGraphic';
import { ListingCardItemNumber } from '@/components/listing/ListingCardItemNumber';
import { getProductConditionTranslationKey } from '@/components/listing/ListingCard/utils';
import { useStopEventPropagation } from '@/hooks/dom';
import { formatRelativeDate } from '@/utils/date';
import { getListingRoute } from '@/utils/routes';
import { Listing } from '@/types/listing';

import style from './StoreListingGridCard.module.sass';

const cx = classNames.bind(style);

type StoreListingGridCardProps = {
  listing: Listing;
  onClick: MouseEventHandler<HTMLButtonElement>;
  checked: boolean;
  isSelectionAllowed?: boolean;
};

export const StoreListingGridCard = ({
  listing,
  onClick,
  checked,
  isSelectionAllowed,
}: StoreListingGridCardProps) => {
  const { i18n, t } = useTranslation();

  const stopEventPropagation = useStopEventPropagation();

  const relativeDate = formatRelativeDate(
    listing.attributes.listingScrapedOn,
    t,
    i18n.language,
    {
      showTime: false,
    },
  );

  const renderListingsIndicators = () => {
    const { latestEnforcement, latestTestPurchase } = listing.linked || {};

    if (!latestEnforcement && !latestTestPurchase) {
      return null;
    }

    return (
      <div className={style.actionIndicators}>
        {
          latestEnforcement &&
          <ListingEnforcementIndicator
            listing={listing}
            onClick={stopEventPropagation}
            withTooltip={!!latestTestPurchase}
          />
        }
        {
          latestTestPurchase &&
          <ListingTestPurchaseIndicator
            listing={listing}
            onClick={stopEventPropagation}
            withTooltip={!!latestEnforcement}
          />
        }
      </div>
    );
  };

  const cardClassName = cx('card', {
    checked,
  });

  return (
    <Card
      className={cardClassName}
      isContextMenuDisabled={true}
      onClick={onClick}
      borderRadiusVariant='lg'>
      <div className={style.imageWrapper}>
        <ImageWithFallback
          className={style.image}
          src={listing.attributes.imageUrl}
        />
        <div className={style.brandContainer}>
          <BrandGraphic
            logoUrl={listing.linked?.brand?.attributes.logoUrl}
            imageClassName={style.brandLogo}
            imageSizeVariant='medium'
            isNameVisible={false}
            name={listing.linked?.brand?.attributes.name}
            hasTooltip={true}
          />
        </div>
      </div>
      <div className={style.cardContent}>
        <StyledLink
          onClick={stopEventPropagation}
          to={getListingRoute(listing.id)}>
          <MultilineHeader
            variant='h4'
            className={style.header}>
            {listing.attributes.productName}
          </MultilineHeader>
        </StyledLink>
        <ListingCardItemNumber listing={listing} />
        <div className={style.priceWrapper}>
          <Body
            size='base'
            className={style.label}>
            {t('listing:filterable.labels.price')}
          </Body>
          <ListingPrice
            price={listing.attributes.price}
            placeholder={t('listing:listingCard.noPrice')}
            className={style.price}
          />
        </div>
        <Body
          size='base'
          className={style.condition}>
          <span className={style.label}>
            {t('listing:filterable.labels.productConditionShort')}
          </span>
          {getProductConditionTranslationKey(t, listing.attributes.isNew)}
        </Body>
        <Body
          size='base'
          className={style.scraped}>
          <span className={style.label}>
            {t('listing:filterable.labels.ageShort')}
          </span>
          {relativeDate}
        </Body>
        <div className={style.cardFooter}>
          {renderListingsIndicators()}
          {
            isSelectionAllowed &&
            <CheckboxInput
              className={style.checkbox}
              value={listing.id}
              onClick={stopEventPropagation}
              checked={checked}
              readOnly={true}
            />
          }
        </div>
      </div>
    </Card>
  );
};
