import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';

import { FieldRenderer } from '@/components/shared/form/FieldRenderer';
import { DebouncedTextInput } from '@/components/shared/form/inputs/DebouncedTextInput';
import { StyledLink } from '@/components/shared/StyledLink';
import { SelectInput } from '@/components/shared/form/inputs/SelectInput';
import { useResellerStoresListingsParams } from '@/hooks/resellers';
import { ITEM_NUMBER, PRODUCT_NAME, AGE, STORE_ID, BRAND_ID, PRODUCT_CONDITION } from '@/resources/queryParams';
import { fillValues, hasAnyNonNullish } from '@/utils/object';
import { getDateOptions } from '@/utils/filters';
import { useBuildClearValue } from '@/hooks/shared';
import { useListingsFilterParams } from '@/hooks/listing';
import { getBrandOptions, getProductConditionOptions } from '@/utils/filters';
import type { AggregationEntry } from '@/types/shared';
import type { ListingsFilterParams } from '@/types/listing';

import style from './ResellerStoreListingsFilters.module.sass';

type ListingsFiltersAggregationMapping = {
  age?: AggregationEntry[];
  brand?: AggregationEntry[];
  condition?: AggregationEntry[];
};

export type ResellerStoreListingsFiltersProps = {
  aggregationMapping?: ListingsFiltersAggregationMapping;
  disabled?: boolean;
};

export const ResellerStoreListingsFilters = ({
  aggregationMapping,
  disabled,
}: ResellerStoreListingsFiltersProps) => {
  const { t } = useTranslation();
  const buildClearValue = useBuildClearValue<ListingsFilterParams>();

  const {
    values,
  } = useResellerStoresListingsParams();

  const {
    setValues,
  } = useFormikContext();

  const resetFilterValues = useCallback(() => {
    const emptyValues = fillValues(values, undefined);

    setValues({
      ...emptyValues,
      [STORE_ID]: values.sellerId,
    });
  }, [
    setValues,
    values,
  ]);

  const {
    setValues: setListingsFilterValues,
  } = useListingsFilterParams();

  const ageAgg = aggregationMapping?.age;

  const dateOptions = useMemo(() => {
    if (!ageAgg) return [];

    return getDateOptions({
      ageAgg,
      t,
      optionWrapperClassName: style.option,
    });
  }, [
    ageAgg,
    t,
  ]);

  const brandAgg = aggregationMapping?.brand;

  const brandOptions = useMemo(() => {
    return getBrandOptions({
      t,
      brandAgg,
    });
  }, [
    brandAgg,
    t,
  ]);

  const conditionAgg = aggregationMapping?.condition;

  const productConditionOptions = useMemo(() => {
    return getProductConditionOptions({
      conditionAgg,
      t,
      optionWrapperClassName: style.option,
    });
  }, [
    conditionAgg,
    t,
  ]);

  const isClearButtonShown = hasAnyNonNullish(values, { ignore: [''], ignoreKeys: [STORE_ID] });

  const handleChange = (name: string) => (newValue: string) => {
    if (newValue === '') {
      setListingsFilterValues({ [name]: undefined });
      return;
    }

    setValues({ [name]: newValue });
  };

  return (
    <div className={style.filtersWrapper}>
      <div className={style.textFiltersWrapper}>
        <FieldRenderer
          name={PRODUCT_NAME}
          Component={DebouncedTextInput}
          clearable={true}
          debounceMs={500}
          sizeVariant='small'
          wrapperClassName={style.filterTextInput}
          iconName='Search'
          onClear={buildClearValue(PRODUCT_NAME)}
          placeholder={t('store:filterable.placeholders.productName')}
          disabled={disabled}
        />
        <FieldRenderer
          name={ITEM_NUMBER}
          Component={DebouncedTextInput}
          clearable={true}
          debounceMs={500}
          sizeVariant='small'
          wrapperClassName={style.filterTextInput}
          iconName='Search'
          onClear={buildClearValue(ITEM_NUMBER)}
          placeholder={t('listing:filterable.placeholders.itemNumber')}
          disabled={disabled}
        />
      </div>
      <FieldRenderer
        name={BRAND_ID}
        Component={SelectInput}
        popperAlign='left'
        className={style.filterSelect}
        placeholder={t('listing:filterable.placeholders.brand')}
        optionVariant='radio'
        options={brandOptions}
        popperTitle={t('listing:filterable.titles.brand')}
        deselectable={true}
        disabled={disabled}
        wrapperClassName={style.filterSelectWrapper}
        optionsClassName={style.optionsClassName}
      />
      <FieldRenderer
        name={PRODUCT_CONDITION}
        Component={SelectInput}
        popperAlign='left'
        className={style.filterSelect}
        placeholder={t('listing:filterable.placeholders.productCondition')}
        optionVariant='radio'
        options={productConditionOptions}
        popperTitle={t('listing:filterable.titles.productCondition')}
        deselectable={true}
        disabled={disabled}
        wrapperClassName={style.filterSelectWrapper}
      />
      <SelectInput
        name={AGE}
        options={dateOptions}
        className={style.filterSelect}
        value={values[AGE] ?? ''}
        onChange={handleChange}
        popperAlign='left'
        placeholder={t('listing:filterable.placeholders.age')}
        optionVariant='radio'
        popperTitle={t('listing:filterable.titles.age')}
        deselectable={true}
        disabled={disabled}
        wrapperClassName={style.filterSelectWrapper}
      />
      {
        isClearButtonShown &&
        <div className={style.clearWrapper}>
          <StyledLink onClick={resetFilterValues}>
            {t('common:filter.clearFilter')}
          </StyledLink>
        </div>
      }
    </div>
  );
};
