import { useSelectedColumns } from '@/hooks/zustand/useSelectedColumns';
import {
  COUNTRY_ID,
  STATUS,
  NAME,
  SUBDOMAIN_ID,
  EMAIL_PRESENCE,
  COMPANY_ID,
  BRANDS_COUNT,
  LISTING_COUNT,
  RATING,
} from '@/resources/queryParams';

export const DEFAULT_STORES_COLUMNS = [
  SUBDOMAIN_ID,
  NAME,
  COUNTRY_ID,
  EMAIL_PRESENCE,
  RATING,
  BRANDS_COUNT,
  LISTING_COUNT,
  STATUS,
  COMPANY_ID,
];

export const STORES_STORAGE_KEY = 'storeListFilters';

export const useStoreColumns = () => {
  return useSelectedColumns(DEFAULT_STORES_COLUMNS, STORES_STORAGE_KEY);
};
