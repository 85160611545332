import { type i18n, type TFunction } from 'i18next';

import { formatDate } from '@/utils/date';
import { DEFAULT_DATE_FORMAT } from '@/resources/datesFormat';

export const getFileDownloadedDateSuffix = (
  t: TFunction,
  i18n: i18n,
  prefix: string,
  fileType?: 'png' | 'jpg' | 'jpeg' | 'pdf' | 'csv',
) => {
  const now = new Date();
  const date = formatDate(
    now,
    t('form:dateTime.dateFormat', DEFAULT_DATE_FORMAT),
  );
  const hours = now.getHours().toString().padStart(2, '0');
  const minutes = now.getMinutes().toString().padStart(2, '0');
  const seconds = now.getSeconds().toString().padStart(2, '0');
  const time = `${hours}${minutes}${seconds}`;

  const fileName = `${prefix}-${date}-${time}.${fileType}`;

  return fileName;
};
