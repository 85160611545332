import { type TFunction } from 'i18next';

import { SelectInput } from '@/components/shared/form/inputs/SelectInput';
import { parens } from '@/utils/string';
import type { AggregationEntry } from '@/types/shared';

type GetProductConditionOptionsProps = {
  conditionAgg?: AggregationEntry[];
  t: TFunction;
  optionWrapperClassName?: string;
};

export const getProductConditionOptions = ({
  conditionAgg,
  t,
  optionWrapperClassName,
}: GetProductConditionOptionsProps) => {
  if (!conditionAgg) {
    return [];
  }

  const optionsData = [
    {
      value: 'true',
      label: t('listing:filterable.productCondition.new'),
      count: conditionAgg.find((entry) => entry?.id?.toString() === 'true')?.count ?? 0,
    },
    {
      value: 'false',
      label: t('listing:filterable.productCondition.other'),
      count: conditionAgg.find((entry) => entry?.id?.toString() === 'false')?.count ?? 0,
    },
    {
      value: 'null',
      label: t('listing:filterable.productCondition.unknown'),
      count: conditionAgg.find((entry) => entry?.id === null)?.count ?? 0,
    },
  ];

  return optionsData.map((datum) => ({
    value: datum.value,
    label: (
      <div className={optionWrapperClassName}>
        <SelectInput.OptionWithSubtitle
          label={datum.label}
          subtitle={parens(datum.count)}
        />
      </div>
    ),
    disabled: datum.count === 0,
  }));
};
