import { create } from 'zustand';
import { persist } from 'zustand/middleware';

type SelectedColumnsState<T extends string> = {
  selectedColumns: T[];
  setSelectedColumns: (columns: T[]) => void;
  resetColumns: () => void;
  getDeselectedCount: () => number;
}

const storeCache: Record<string, unknown> = {};

export const useSelectedColumns = <T extends string>(
  defaultColumns: T[],
  storageKey: string,
) => {
  if (!storeCache[storageKey]) {
    storeCache[storageKey] = create<SelectedColumnsState<T>>()(
      persist(
        (set, get) => ({
          selectedColumns: defaultColumns,
          setSelectedColumns: (columns: T[]) =>
            set(() => ({ selectedColumns: columns })),
          resetColumns: () => {
            set(() => ({ selectedColumns: defaultColumns }));
          },
          getDeselectedCount: () => {
            return defaultColumns.length - get().selectedColumns.length;
          },
        }),
        {
          name: storageKey,
        },
      ),
    );
  }

  return (storeCache[storageKey] as () => SelectedColumnsState<T>)();
};
