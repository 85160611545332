import { useMemo } from 'react';

import { Stores } from '@/components/store/Stores/Stores';
import { Pagination } from '@/components/pagination/Pagination';
import { WithQueryLoader } from '@/components/shared/WithQueryLoader';
import { StoresViewToolbar } from '@/components/store/StoresViewToolbar';
import { BusyContentContainer } from '@/components/shared/containers/BusyContentContainer';
import { useViewModeContext } from '@/contexts/shared';
import { getAvailableAggregationChannels } from '@/utils/filters/getAvailableAggregationChannels';
import { hasAnyNonNullish } from '@/utils/object';
import { useChannels } from '@/hooks/channel';
import { Store, StoresFilterParams, StoresRequestMeta } from '@/types/store';
import { UseQueryError } from '@/types/shared';

type DataMappingProperties = {
  stores: Store[];
  meta: StoresRequestMeta | undefined;
} | undefined;

type DataMapping = {
  country: DataMappingProperties;
  subdomain: DataMappingProperties;
  status?: DataMappingProperties;
  email?: DataMappingProperties;
  brand?: DataMappingProperties;
  company?: DataMappingProperties;
  condition?: DataMappingProperties;
} | undefined;

type StoresError = UseQueryError<{
  stores: Store[];
  meta: StoresRequestMeta | undefined;
}> | null;

type StoresViewProps = {
  dataMapping: DataMapping;
  stores?: Store[];
  scopedMeta?: StoresRequestMeta;
  storesError: StoresError;
  values: StoresFilterParams;
  isStoresLoading: boolean;
  isMetaMappingLoading: boolean;
  dataMappingError?: Error;
};

export const StoresView = ({
  dataMapping,
  stores,
  scopedMeta,
  isStoresLoading,
  storesError,
  values,
  isMetaMappingLoading,
  dataMappingError,
}: StoresViewProps) => {

  const {
    isChanging,
  } = useViewModeContext();

  const {
    channels: allChannels,
    isLoading: isChannelsLoading,
    error: channelsError,
  } = useChannels();

  const countries = dataMapping?.subdomain?.meta?.aggregation.country;
  const storesSubdomains = dataMapping?.subdomain?.meta?.aggregation.subdomain;

  const channels = useMemo(() => {
    return getAvailableAggregationChannels(allChannels, storesSubdomains);
  }, [allChannels, storesSubdomains]);

  const isLoading = isChannelsLoading || isStoresLoading || isMetaMappingLoading;
  const error = channelsError || storesError || dataMappingError;

  const isFilterActive = hasAnyNonNullish(values);

  const renderStores = () => {
    if (!stores) {
      return null;
    }

    return (
      <BusyContentContainer isBusy={isChanging}>
        <Stores
          pages={scopedMeta?.pagination.totalPages || 0}
          stores={stores}
          isFilterActive={isFilterActive}
        />
      </BusyContentContainer>
    );
  };

  return (
    <>
      <StoresViewToolbar
        channels={channels}
        filterValues={values}
        aggregationMapping={{
          subdomain: storesSubdomains,
          country: dataMapping?.country?.meta?.aggregation.country,
          status: dataMapping?.status?.meta?.aggregation.status,
          email: dataMapping?.email?.meta?.aggregation.emailPresence,
          brand: dataMapping?.brand?.meta?.aggregation.brand,
          company: dataMapping?.company?.meta?.aggregation.company,
          condition: dataMapping?.condition?.meta?.aggregation['listings.product_condition'],
        }}
        areFiltersDisabled={isMetaMappingLoading}
        countries={countries}
        totalEntries={scopedMeta?.pagination.totalEntries}
      />
      <WithQueryLoader
        error={error}
        isLoading={isLoading}>
        {renderStores()}
      </WithQueryLoader>
      {
        scopedMeta &&
        <Pagination
          itemsCount={stores?.length}
          totalItemsCount={scopedMeta.pagination.totalEntries}
          pages={scopedMeta.pagination.totalPages}
        />
      }
    </>
  );
};
