import { QueryFunctionContext } from 'react-query';
import produce from 'immer';

import { Api } from '@/utils/api';
import {
  linkCollectionRelationships,
} from '@/utils/queries/linkCollectionRelationships';
import { type UseStoreParams } from '@/hooks/stores/useCompanyStores';
import type {
  StoresRequestMeta,
  Store,
  StoreSubdomain,
  StoreReseller,
  StoreSubdomainChannel,
  StoresFilterParams,
} from '@/types/store';
import { Nullable } from '@/types/shared';

export type StoresQueryKey = [string, UseStoreParams | undefined]

type StoresFilterQuery = StoresFilterParams & {
  isNew: never;
  'listings.product_condition': Nullable<string>;
};

type StoresIncludedRecord =
  | StoreSubdomain
  | StoreReseller
  | StoreSubdomainChannel

const buildStoresFilterParams = (filter: StoresFilterParams | undefined) => {
  if (!filter) return filter;

  return produce<StoresFilterParams, StoresFilterQuery>(filter, (draft) => {
    draft['listings.product_condition'] = draft.isNew;
    delete draft.isNew;
  });
};

export const fetchCompanyStores = async (
  context: QueryFunctionContext<StoresQueryKey>,
) => {
  const [, params = {}] = context.queryKey;

  const {
    pagination,
    sort,
    filter,
  } = params;

  const filterParams = buildStoresFilterParams(filter);

  const page = pagination && {
    size: pagination.perPage,
    number: pagination.page,
  };

  const response = await Api.get<
    Store[],
    StoresIncludedRecord,
    StoresRequestMeta
  >('api/company_stores', {
    params: {
      page,
      sort: sort?.sort,
      filter: filterParams,
    },
  });

  const parsedStores = linkCollectionRelationships(response);

  return {
    stores: parsedStores,
    meta: response.meta,
  };
};
