import { create } from 'zustand';

type State = {
  botsResultsNumber: number | undefined;
}

type Action = {
  updateBotsResultNumber: (botsResultsNumber: State['botsResultsNumber']) => void;
};

export const useBotsResultNumber = create<State & Action>((set) => ({
  botsResultsNumber: undefined,
  updateBotsResultNumber: (botsResultsNumber) => set(
    () => ({ botsResultsNumber: botsResultsNumber }),
  ),
}));
