import { RouteParam } from '@/types/shared';

export const getAdminRoute = () => {
  return '/admin';
};

export const getAdminUsersRoute = () => {
  return `${getAdminRoute()}/users`;
};

export const getAdminUserRoute = (userId: RouteParam) => {
  return `${getAdminUsersRoute()}/${userId}`;
};

export const getAdminCreateUserRoute = () => {
  return `${getAdminUsersRoute()}/create`;
};

export const getAdminDeleteUserRoute = (userId: RouteParam) => {
  return `${getAdminUserRoute(userId)}/delete`;
};

export const getAdminEditUserRoute = (userId: RouteParam) => {
  return `${getAdminUserRoute(userId)}/edit`;
};

export const getAdminTrademarksRoute = () => {
  return `${getAdminRoute()}/trademarks`;
};

export const getAdminTrademarkRoute = (trademarkId: RouteParam) => {
  return `${getAdminRoute()}/trademarks/${trademarkId}`;
};

export const getAdminCreateTrademarkRoute = () => {
  return `${getAdminTrademarksRoute()}/create`;
};

export const getAdminEditTrademarkRoute = (trademarkId: RouteParam) => {
  return `${getAdminTrademarkRoute(trademarkId)}/edit`;
};

export const getAdminDeleteTrademarkRoute = (trademarkId: RouteParam) => {
  return `${getAdminTrademarkRoute(trademarkId)}/delete`;
};

export const getAdminEditCompanyRoute = () => {
  return `${getAdminRoute()}/edit`;
};

export const getAdminBotsRoute = () => {
  return `${getAdminRoute()}/harvesters`;
};

export const getAdminCreateBotRoute = () => {
  return `${getAdminBotsRoute()}/create`;
};

export const getAdminCreateMultipleBotsRoute = () => {
  return `${getAdminCreateBotRoute()}/multiple`;
};

export const getAdminBotRoute = (botId: RouteParam) => {
  return `${getAdminBotsRoute()}/${botId}`;
};

export const getAdminDeleteBotRoute = (botId: RouteParam) => {
  return `${getAdminBotRoute(botId)}/delete`;
};

export const getAdminEditBotRoute = (botId: RouteParam) => {
  return `${getAdminBotRoute(botId)}/edit`;
};

export const getAdminEditMultipleBotRoute = () => {
  return `${getAdminBotsRoute()}/edit/multiple`;
};

export const getAdminKeywordsRoute = () => {
  return `${getAdminBotsRoute()}/keywords`;
};

export const getAdminKeywordRoute = (keywordId: RouteParam) => {
  return `${getAdminKeywordsRoute()}/${keywordId}`;
};

export const getAdminEditKeywordRoute = (keywordId: RouteParam) => {
  return `${getAdminKeywordRoute(keywordId)}/edit`;
};

export const getAdminDeleteKeywordRoute = (keywordId: RouteParam) => {
  return `${getAdminKeywordRoute(keywordId)}/delete`;
};

export const getAdminCreateMultipleKeywordsRoute = () => {
  return `${getAdminKeywordsRoute()}/create/multiple`;
};

export const getAdminLoasRoute = () => {
  return `${getAdminRoute()}/letters-of-authorisations`;
};

export const getAdminLoaRoute = (loaId: RouteParam) => {
  return `${getAdminLoasRoute()}/${loaId}`;
};

export const getAdminDownloadToSignLoaRoute = (loaId: RouteParam) => {
  return `${getAdminLoaRoute(loaId)}/download-to-sign`;
};

export const getAdminUploadSignedLoaRoute = (loaId: RouteParam) => {
  return `${getAdminLoaRoute(loaId)}/upload-signed`;
};

export const getAdminCreateLoaRoute = () => {
  return `${getAdminLoasRoute()}/create`;
};

export const getAdminEditLoaRoute = (loaId: RouteParam) => {
  return `${getAdminLoaRoute(loaId)}/edit`;
};

export const getAdminDuplicateLoaRoute = (loaId: RouteParam) => {
  return `${getAdminLoaRoute(loaId)}/duplicate`;
};

export const getAdminEnforcementPolicyPreviewRoute = () => {
  return `${getAdminRoute()}/enforcement-policy-preview`;
};

export const getAdminBrandsRoute = () => {
  return `${getAdminRoute()}/brands`;
};

export const getAdminBrandRoute = (brandId: RouteParam) => {
  return `${getAdminBrandsRoute()}/${brandId}`;
};
export const getAdminCreateBrandRoute = () => {
  return `${getAdminBrandsRoute()}/create`;
};

export const getAdminEditBrandRoute = (brandId: RouteParam) => {
  return `${getAdminBrandRoute(brandId)}/edit`;
};

export const getAdminDeleteBrandRoute = (brandId: RouteParam) => {
  return `${getAdminBrandRoute(brandId)}/delete`;
};

export const getAdminEnforcementPoliciesRoute = () => {
  return `${getAdminRoute()}/enforcement-policies`;
};

export const getAdminEnforcementPolicyRoute = (enforcementPolicyId: RouteParam) => {
  return `${getAdminRoute()}/enforcement-policies/${enforcementPolicyId}`;
};

export const getAdminCreateEnforcementPolicyRoute = () => {
  return `${getAdminEnforcementPoliciesRoute()}/create`;
};

export const getAdminEditEnforcementPolicyRoute = (enforcementPolicyId: RouteParam) => {
  return `${getAdminEnforcementPolicyRoute(enforcementPolicyId)}/edit`;
};

