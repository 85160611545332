import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { Button } from '@/components/shared/buttons';
import { Body } from '@/components/shared/typography/Body';
import { Tooltip } from '@/components/shared/Tooltip';
import { useRoutePathContext } from '@/contexts/shared';
import { useResourceSelectIds } from '@/hooks/zustsand/resourceSelectIds';
import { BRAND_ID, SCHEDULE } from '@/resources/queryParams';
import { BOTS_SELECTION_KEY } from '@/resources/bot';
import { ViewMode } from '@/resources/enums';
import { useBotsResultNumber } from '@/hooks/zustsand/botsResultNumber';

import style from './UpdateBotsControls.module.sass';

export const UpdateBotsControls = ({
  companyId,
}: {
  companyId: string;
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const botsResultsNumber = useBotsResultNumber((state) => state.botsResultsNumber);

  const location = useLocation();
  const isOnPageWithGridView = location.search.includes(`view=${ViewMode.Grid}`);

  const { setIsSelecting, isSelecting, ids } = useResourceSelectIds(BOTS_SELECTION_KEY);

  const {
    getInstanceBotEditMultipleRoute,
  } = useRoutePathContext();

  if (isOnPageWithGridView) {
    return null;
  }

  const renderActionButtons = () => {
    if (!isSelecting) {
      return null;
    }

    const navigateToEditBrand = () => {
      navigate(getInstanceBotEditMultipleRoute(companyId), {
        state: {
          companyId,
          ids,
          property: BRAND_ID,
          shouldNavigateToAdminBotsView: !companyId,
        },
      });
    };

    const navigateToEditSchedule = () => {
      navigate(getInstanceBotEditMultipleRoute(companyId), {
        state: {
          companyId,
          ids,
          property: SCHEDULE,
          /* noteToSelf
            what is this shouldNavigateToAdminBotsView?
           */
          shouldNavigateToAdminBotsView: !companyId,
        },
      });
    };

    return (
      <Tooltip
        startDelay={500}
        disabled={Boolean(ids.length)}
        content={t('bot:multipleUpdate.selectBots')}>
        <div className={style.actionButtons}>
          <Button
            disabled={!ids.length}
            onClick={navigateToEditBrand}>
            {t('bot:multipleUpdate.changeBrand')}
          </Button>
          <Button
            disabled={!ids.length}
            onClick={navigateToEditSchedule}>
            {t('bot:multipleUpdate.changeSchedule')}
          </Button>
        </div>
      </Tooltip>
    );
  };

  const isChangeMultipleButtonDisabled = botsResultsNumber === 0;

  return (
    <div className={style.wrapper}>
      {
        ids.length ?
          <Body size='small'>
            {t('bot:multipleUpdate.countSelected', { count: ids.length })}
          </Body>
          : null
      }
      {renderActionButtons()}
      <Button
        variant='light'
        disabled={isChangeMultipleButtonDisabled}
        onClick={() => setIsSelecting(!isSelecting)}>
        {
          isSelecting
            ? t('bot:multipleUpdate.cancel')
            : t('bot:multipleUpdate.changeMultiple')
        }
      </Button>
    </div>
  );
};

/* noteToSelf
  for future use
 */

{/* <Tooltip
  disabled={isSelectButtonDisabled}
  content={<Body size='base'>{t('bot:multipleUpdate.brand.companyIdRequired')}</Body>}>
  <div>
</Tooltip>
          </div > */}
