import { useQueryParams, StringParam } from 'use-query-params';

import { ITEM_NUMBER, PRODUCT_NAME, SUBDOMAIN_ID, AGE, STORE_NAME, STORE_STATUS, BRAND_ID, PRODUCT_CONDITION } from '@/resources/queryParams';

export const useListingsFilterParams = () => {
  const [values, setValues] = useQueryParams({
    [SUBDOMAIN_ID]: StringParam,
    [PRODUCT_NAME]: StringParam,
    [ITEM_NUMBER]: StringParam,
    [AGE]: StringParam,
    [STORE_NAME]: StringParam,
    [STORE_STATUS]: StringParam,
    [BRAND_ID]: StringParam,
    [PRODUCT_CONDITION]: StringParam,
  });

  return {
    values,
    setValues,
  };
};
