import { useQueryParams, StringParam } from 'use-query-params';

import { BRAND_ID, COMPANY_ID, COUNTRY_ID, EMAIL_PRESENCE, NAME, PRODUCT_CONDITION, STATUS, SUBDOMAIN_ID } from '@/resources/queryParams';

export const useStoresFilterParams = () => {
  const [values, setValues] = useQueryParams({
    [NAME]: StringParam,
    [COUNTRY_ID]: StringParam,
    [SUBDOMAIN_ID]: StringParam,
    [EMAIL_PRESENCE]: StringParam,
    [STATUS]: StringParam,
    [PRODUCT_CONDITION]: StringParam,
    [BRAND_ID]: StringParam,
    [COMPANY_ID]: StringParam,
  });

  return {
    values,
    setValues,
  };
};
