import { Formik } from 'formik';

import { FormikForm } from '@/components/shared/formik/FormikForm';
import { BotsSortSelect } from '@/components/bot/BotsSortSelect';
import { BotsFilters } from '@/components/bot/BotsFilters';
import { ViewModeSelector } from '@/components/shared/viewMode/ViewModeSelector';
import { BotFilterEntries } from '@/components/bot/BotFilterEntries';
import { type BotsFiltersProps } from '@/components/bot/BotsFilters/BotsFilters';
import { FilteringResultsCount } from '@/components/shared/FilteringResultsCount';
import { BotsListTableSettings } from '@/components/bot/BotsListTableSettings';
import { useBotsFilterParams } from '@/hooks/bot/useBotsFilterParams';
import { usePaginationParams } from '@/hooks/shared/usePaginationParams';
import { useIsOnInstance } from '@/hooks/shared';
import { removeEmptyValues } from '@/utils/queries/removeEmptyValues';
import type { Channel } from '@/types/channel';
import type { BotsFilterParams } from '@/types/bot';

import style from './BotsViewToolbar.module.sass';

type BotsViewToolbarProps = {
  channels?: Channel[];
  aggregationMapping: BotsFiltersProps['aggregationMapping'];
  filterHiddenFields: BotsFiltersProps['hiddenFields'];
  areFiltersDisabled?: boolean;
  filterValues: BotsFilterParams;
  totalEntries: number | undefined;
};

export const BotsViewToolbar = ({
  channels,
  aggregationMapping,
  areFiltersDisabled,
  filterHiddenFields,
  filterValues,
  totalEntries,
}: BotsViewToolbarProps) => {
  const {
    setValues,
  } = useBotsFilterParams();

  const {
    isAdminInstance,
  } = useIsOnInstance();

  const {
    updatePage,
  } = usePaginationParams();

  const updateQueryParams = (values: BotsFilterParams) => {
    updatePage(1);
    setValues(removeEmptyValues(values));
  };

  const renderTableSettings = () => {
    if (!isAdminInstance) {
      return null;
    }

    return (
      <div className={style.dropdownTriggerWrapper}>
        <BotsListTableSettings />
      </div>
    );
  };

  return (
    <div className={style.mainWrapper}>
      <div className={style.toolbarAside}>
        <BotsSortSelect />
        {renderTableSettings()}
        <ViewModeSelector />
      </div>
      <div>
        <Formik
          validateOnBlur={false}
          enableReinitialize={true}
          validate={updateQueryParams}
          onSubmit={updateQueryParams}
          initialValues={filterValues}>
          <FormikForm
            className={style.wrapper}>
            <div className={style.mainToolbar}>
              <BotsFilters
                aggregationMapping={aggregationMapping}
                hiddenFields={filterHiddenFields}
                disabled={areFiltersDisabled}
                channels={channels || []}
              />
            </div>
            <BotFilterEntries
              filterValues={filterValues}
              aggregationMapping={aggregationMapping}
            />
            <FilteringResultsCount
              totalEntries={totalEntries}
              values={filterValues}
            />
          </FormikForm>
        </Formik>
      </div>
    </div>
  );
};
