import { useParams } from 'react-router-dom';

import { useSelectedColumns } from '@/hooks/zustand/useSelectedColumns';
import {
  NAME,
  LATEST_RUN,
  SUBDOMAIN_ID,
  COMPANY_ID,
  BRAND_ID,
} from '@/resources/queryParams';
import { useIsOnInstance } from '@/hooks/shared';

export const DEFAULT_BOTS_COLUMNS = [
  SUBDOMAIN_ID,
  NAME,
  COMPANY_ID,
  BRAND_ID,
  LATEST_RUN,
];

const BOTS_STORAGE_KEY = 'botsListColumns';

export const useBotsColumns = () => {
  const {
    isAdminInstance,
    isCustomerInstance,
  } = useIsOnInstance();

  const {
    companyId,
  } = useParams();

  const isOnGsCompanyRoute = companyId && isAdminInstance;
  const isOnCustomerRoute = isCustomerInstance;

  let storageKeyPrefix = 'main';
  if (isOnGsCompanyRoute) {
    storageKeyPrefix = 'client_company';
  } else if (isOnCustomerRoute) {
    storageKeyPrefix = 'customer';
  }

  const storageKey = `${storageKeyPrefix}_${BOTS_STORAGE_KEY}`;

  return useSelectedColumns(DEFAULT_BOTS_COLUMNS, storageKey);
};
