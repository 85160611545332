import { useTranslation } from 'react-i18next';

import { TableSettingsWithViewMode } from '@/components/shared/TableSettingsWithViewMode';
import { useStoreColumns } from '@/hooks/stores';
import {
  COUNTRY_ID,
  STATUS,
  SUBDOMAIN_ID,
  NAME,
  BRANDS_COUNT,
  LISTING_COUNT,
  RATING,
  EMAIL_PRESENCE,
} from '@/resources/queryParams';
import { useIsOnInstance } from '@/hooks/shared';

export const StoresListTableSettings = () => {
  const { t } = useTranslation();

  const {
    isCustomerInstance,
    isAdminInstance,
  } = useIsOnInstance();

  const options = [
    { id: SUBDOMAIN_ID, label: t('store:attributes.channel'), disabled: true },
    { id: NAME, label: t('store:attributes.name'), disabled: true },
    { id: COUNTRY_ID, label: t('store:attributes.country') },
    { id: EMAIL_PRESENCE, label: t('store:attributes.email'), hidden: isCustomerInstance },
    { id: RATING, label: t('store:attributes.rating') },
    { id: BRANDS_COUNT, label: t('store:attributes.brandsCount'), hidden: isAdminInstance },
    { id: LISTING_COUNT, label: t('store:attributes.listingCount'), hidden: isAdminInstance },
    { id: STATUS, label: t('store:attributes.status'), hidden: isAdminInstance },
  ];

  return (
    <TableSettingsWithViewMode
      options={options}
      useSelectedColumns={useStoreColumns()}
    />
  );
};
