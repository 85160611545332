import classNames from 'classnames/bind';
import { memo, type PropsWithChildren } from 'react';

import { RadioInput } from '@/components/shared/form/inputs/RadioInput';
import { Icon } from '@/components/shared/Icon';
import { CheckboxInput } from '@/components/shared/form/inputs/CheckboxInput';

import style from './SelectInputLabel.module.sass';

const cx = classNames.bind(style);

export type OptionVariant =
  | 'tickLeft'
  | 'tickRight'
  | 'checkbox'
  | 'radio';

type SelectInputLabelProps = PropsWithChildren<{
  optionVariant: OptionVariant;
  active?: boolean;
  selected?: boolean;
  disabled?: boolean;
}>;

export const SelectInputLabel = memo(({
  children,
  optionVariant,
  active,
  selected,
  disabled,
}: SelectInputLabelProps) => {
  if (optionVariant === 'radio') {
    const wrapperClass = cx('radioWrapper', {
      disabled,
      active,
    });

    return (
      <div className={wrapperClass}>
        <RadioInput
          className={style.radioInput}
          checked={selected}
          label={children}
          readOnly={true}
        />
      </div>
    );
  }

  if (optionVariant === 'checkbox') {
    const wrapperClass = cx('checkboxWrapper', {
      disabled,
      active,
    });

    return (
      <div className={wrapperClass}>
        <CheckboxInput
          checked={selected}
          readOnly={true}
          className={style.checkboxInput}
          disabled={disabled}
        />
        <div className={style.checkboxLabel}>
          {children}
        </div>
      </div>
    );
  }

  const selectedIconClass = cx('selectedIcon', optionVariant);
  const wrapperClass = cx('tickWrapper', optionVariant, {
    active,
    selected,
    disabled,
  });

  return (
    <div className={wrapperClass}>
      {children}
      <Icon name='Done' className={selectedIconClass} />
    </div>
  );
});

SelectInputLabel.displayName = 'SelectInputLabel';
