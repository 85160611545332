import { useQueryParams, StringParam } from 'use-query-params';

import { AGE, BRAND_ID, ITEM_NUMBER, PAGE, PRODUCT_NAME, PRODUCT_CONDITION, STORE_ID } from '@/resources/queryParams';

export const useResellerStoresListingsParams = () => {
  const [values, setValues] = useQueryParams({
    [PRODUCT_NAME]: StringParam,
    [ITEM_NUMBER]: StringParam,
    [AGE]: StringParam,
    [PAGE]: StringParam,
    [STORE_ID]: StringParam,
    [BRAND_ID]: StringParam,
    [PRODUCT_CONDITION]: StringParam,
  });

  return {
    values,
    setValues,
  };
};
