import { useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

import { useFilteredQueriesMapping } from '@/hooks/utils';
import { fetchListings } from '@/queries/listing';
import { removeListingFilterValue } from '@/utils/filters';
import { notification } from '@/utils/notification';
import { getCreateEnforcementRoute, getCreateTestPurchaseRoute } from '@/utils/routes';
import { type UseListingsParams } from '@/hooks/listing';
import type { Listing } from '@/types/listing';

import { checkIsActive } from './utils';

type UseRequestCreateEnforcementParams = {
  selectedListings: Listing[] | undefined;
  onAfterNotificationCreate: (notificationId: string) => void;
  storeId: string;
};

type UseRequestCreateTestPurchaseParams = {
  selectedListings: Listing[] | undefined;
  onAfterNotificationCreate: (notificationId: string) => void;
  storeId: string;
};

type UseRequestCreateEnforcementOrTestPurchaseParams = {
  context: 'enforcement' | 'testPurchase';
  checkListing: (listing: Listing) => boolean;
  redirect: (allowedListings: Listing[]) => void;
  selectedListings: Listing[] | undefined;
  onAfterNotificationCreate: (notificationId: string) => void;
};

export const useFilteredListingsQueriesMapping = (
  queryKeyParams: UseListingsParams,
) => {
  return useFilteredQueriesMapping({
    queryFn: fetchListings,
    transformQueryKey: (queryKey, params) => {
      return removeListingFilterValue(queryKey, params.filterKey);
    },
    queryKey: ['listings', queryKeyParams],
    mapping: [
      { dataKey: 'age', params: { filterKey: 'age' as const } },
      { dataKey: 'storeStatus', params: { filterKey: 'storeStatus' as const } },
      { dataKey: 'brand', params: { filterKey: 'brandId' as const } },
      { dataKey: 'condition', params: { filterKey: 'isNew' as const } },
    ],
  });
};

export const useRequestCreateEnforcement = ({
  selectedListings,
  onAfterNotificationCreate,
  storeId,
}: UseRequestCreateEnforcementParams) => {
  const navigate = useNavigate();

  const navigateToCreateEnforcement = useCallback((allowedListings: Listing[]) => {
    const targetRoute = getCreateEnforcementRoute(storeId);
    const state = { selectedListings: allowedListings };

    navigate(targetRoute, { state });

    toast.dismiss();
  }, [
    storeId,
    navigate,
  ]);

  return useRequestCreateEnforcementOrTestPurchase({
    checkListing: (listing) => !checkIsActive(listing.linked?.latestEnforcement),
    selectedListings: selectedListings,
    redirect: navigateToCreateEnforcement,
    onAfterNotificationCreate: onAfterNotificationCreate,
    context: 'enforcement',
  });
};

export const useRequestCreateTestPurchase = ({
  selectedListings,
  onAfterNotificationCreate,
  storeId,
}: UseRequestCreateTestPurchaseParams) => {
  const navigate = useNavigate();

  const navigateToCreateTestPurchase = useCallback((allowedListings: Listing[]) => {
    const targetRoute = getCreateTestPurchaseRoute(storeId);
    const state = { selectedListings: allowedListings };

    navigate(targetRoute, { state });

    toast.dismiss();
  }, [
    storeId,
    navigate,
  ]);

  return useRequestCreateEnforcementOrTestPurchase({
    checkListing: (listing) => !checkIsActive(listing.linked?.latestTestPurchase),
    selectedListings: selectedListings,
    redirect: navigateToCreateTestPurchase,
    onAfterNotificationCreate: onAfterNotificationCreate,
    context: 'testPurchase',
  });
};

export const useRequestCreateEnforcementOrTestPurchase = ({
  selectedListings,
  redirect,
  onAfterNotificationCreate,
  checkListing,
  context,
}: UseRequestCreateEnforcementOrTestPurchaseParams) => {
  const { t } = useTranslation();

  return useCallback(() => {
    if (!selectedListings) return;

    const allowedListings = selectedListings?.filter(checkListing);

    if (allowedListings.length === selectedListings.length) {
      redirect(allowedListings);
      return;
    }

    if (allowedListings.length === 0) {
      const notificationId = notification.danger({
        title: t('listing:messages.alreadyCreated', { context }),
        body: t('listing:messages.notAllowed', { context }),
        duration: 5000,
      });

      return onAfterNotificationCreate(notificationId);
    }

    const notificationText = t('listing:messages.createFor', {
      count: allowedListings.length,
      context: context,
    });

    const notificationId = notification.danger({
      title: t('listing:messages.alreadyCreated', { context }),
      body: t('listing:messages.askIfAllowed', { context }),
      duration: Infinity,
      action: {
        size: 'small',
        variant: 'secondary',
        text: notificationText,
        onClick: () => {
          toast.dismiss(notificationId);
          redirect(allowedListings);
        },
      },
    });

    onAfterNotificationCreate(notificationId);
  }, [
    selectedListings,
    t,
    redirect,
    onAfterNotificationCreate,
    checkListing,
    context,
  ]);
};

export const useOverrideNotification = () => {
  const latestNotificationId = useRef<string>();

  return useCallback((notificationId: string) => {
    if (latestNotificationId.current) {
      toast.dismiss(latestNotificationId.current);
    }

    latestNotificationId.current = notificationId;
  }, []);
};
