import { useTranslation } from 'react-i18next';
import {
  useCallback,
  useMemo,
} from 'react';

import { Body } from '@/components/shared/typography/Body';
import { ListingPrice } from '@/components/shared/Listing/ListingPrice';
import { VerticalTable } from '@/components/shared/tables/VerticalTable';
import { EmptyCollection } from '@/components/shared/EmptyCollection';
import { ListingTitleWithImage } from '@/components/shared/Listing/ListingTitleWithImage';
import { ListingEnforcementIndicator } from '@/components/listing/ListingEnforcementIndicator';
import { ListingTestPurchaseIndicator } from '@/components/listing/ListingTestPurchaseIndicator';
import { BrandGraphic } from '@/components/brand/BrandGraphic';
import { ListingsListItemNumber } from '@/components/listing/ListingsListItemNumber';
import { getProductConditionTranslationKey } from '@/components/listing/ListingCard/utils';
import { useMediaQuery } from '@/hooks/shared';
import { formatRelativeDate } from '@/utils/date';
import { useListingsSelectionParams } from '@/hooks/listing';
import { useStopEventPropagation } from '@/hooks/dom';
import { getListingRoute } from '@/utils/routes';
import type { Listing } from '@/types/listing';
import type { TableConfig } from '@/types/tables';

import style from './ResellerStoreListingsList.module.sass';

type ResellerStoreListingsListProps = {
  listings: Listing[];
  storeStatus: string;
  isSelectionAllowed?: boolean;
};

export const ResellerStoreListingsList = ({
  listings,
  isSelectionAllowed,
}: ResellerStoreListingsListProps) => {
  const { i18n, t } = useTranslation();

  const isNearlyMobile = useMediaQuery('(max-width: 900px)');
  const isDesktop = useMediaQuery('(min-width: 1280px)');
  const enforcementAndTestPurchaseTranslationContext = isDesktop ? undefined : 'short';

  const [
    selectedListingIds,
    setSelectedListingIds,
  ] = useListingsSelectionParams();

  const stopEventPropagation = useStopEventPropagation();

  const generateSelectableConfig = useCallback(() => {
    if (!isSelectionAllowed) {
      return undefined;
    }

    return {
      selectedIds: selectedListingIds,
      onSelect: setSelectedListingIds,
      bodyCheckboxDisabledTooltip: t('store:listingsList.listingNotSelectable'),
    };
  }, [
    t,
    isSelectionAllowed,
    selectedListingIds,
    setSelectedListingIds,
  ]);

  const config = useMemo<TableConfig<Listing>>(() => ({
    selectable: generateSelectableConfig(),
    columns: [
      {
        header: t('store:listingsList.headers.name'),
        render: (listing) => (
          <ListingTitleWithImage
            productImageUrl={listing.attributes.imageUrl}
            productName={listing.attributes.productName}
            headerVariant='h5'
            listingRoute={getListingRoute(listing.id)}
            nameWrapperClassName={style.nameWrapper}
          />
        ),
        colSpan: isDesktop ? 2 : 3,
      },
      {
        header: t('listing:listHeaders.brand'),
        render: (listing) => (
          <BrandGraphic
            logoUrl={listing.linked?.brand?.attributes.logoUrl}
            name={listing.linked?.brand?.attributes.name}
            nameSizeVariant='small'
          />
        ),
        width: 200,
      },
      {
        header: t('listing:listHeaders.itemNumber'),
        render: (listing) => (
          <ListingsListItemNumber listing={listing} />
        ),
        colSpan: isDesktop ? 1 : 2,
        hidden: isNearlyMobile,
        className: style.itemNumberColumn,
      },
      {
        header: t('listing:listHeaders.price'),
        render: (listing) => (
          <ListingPrice
            variant='secondary'
            price={listing.attributes.price}
            placeholder={t('listing:listingCard.noPrice')}
            className={style.price}
          />
        ),
        colSpan: 1,
        hidden: !isDesktop,
      },
      {
        header: t('listing:listHeaders.productCondition'),
        render: (listing) => (
          <Body size='base'>
            {getProductConditionTranslationKey(t, listing.attributes.isNew)}
          </Body>
        ),
      },
      {
        header: t('listing:listHeaders.scrapedAt'),
        render: (listing) => {
          const relativeDate = formatRelativeDate(
            listing.attributes.listingScrapedOn,
            t,
            i18n.language,
            {
              showTime: false,
            },
          );

          return (
            <Body size='base'>
              {relativeDate}
            </Body>
          );
        },
        colSpan: 1,
        hidden: !isDesktop,
      },
      {
        header: t('listing:listHeaders.actions'),
        render: (listing) => (
          <div className={style.actionIndicators}>
            <ListingEnforcementIndicator
              listing={listing}
              translationContext={enforcementAndTestPurchaseTranslationContext}
              onClick={stopEventPropagation}
            />
            <ListingTestPurchaseIndicator
              listing={listing}
              translationContext={enforcementAndTestPurchaseTranslationContext}
              onClick={stopEventPropagation}
            />
          </div>
        ),
        colSpan: !isDesktop ? 1 : 2,
        className: style.actionsWrapper,
      },
    ],
    getRowClassName: () => style.rowWrapper,
  }
  ), [
    t,
    i18n.language,
    isDesktop,
    isNearlyMobile,
    enforcementAndTestPurchaseTranslationContext,
    stopEventPropagation,
    generateSelectableConfig,
  ]);

  if (!listings.length) {
    return (
      <EmptyCollection
        header={t('reseller:list.noResults')}
        animatedMediaName='MagnifyingGlass'
      />
    );
  }

  return (
    <VerticalTable
      data={listings}
      config={config}
    />
  );
};
